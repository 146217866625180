import axios from 'axios';
import { BASE_URL } from '.';


export const AuthApi = {

    singUp: async (data) => {
        const url = `${BASE_URL}/signUp`
        try {
            const response = await axios.post(url,data, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "multipart/form-data"
                }
            });
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.', error)
            return error.response.data
        }
    },
    singIn: async (data) => {
        const url = `${BASE_URL}/signIn`
        try {
            const response = await axios.post(url,data);
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.')
            return error.response.data
        }
    },
    logout: async () => {
        let user = localStorage.getItem('user');
        if(user)
        {
            user = await JSON.parse(user)
            await AuthApi.setLoggedIn({_id: user._id, is_logged_in: false})
        }

        localStorage.removeItem('isloggedin');
        localStorage.removeItem('user');
        await localStorage.removeItem('token');
    },

    loggedInUsers: async () => {
        const url = `${BASE_URL}/logged_in_counts`
        try {
            const response = await axios.get(url);
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.')
            return error.response.data
        }
    },

    setLoggedIn: async (data) => {
        const url = `${BASE_URL}/logged_in`
        try {
            const response = await axios.post(url, data);
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.')
            return error.response.data
        }
    },
}