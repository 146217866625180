import React from "react";
import { Link } from "react-router-dom";
import CustomModal from "../Components/CustomModal/CustomModal";
// import book from "../../public/assets/pdfs/HTML/DAY-1/Containers-Tables-Lists/container-table-1.pdf"
// import pdfOne from "assets/pdfs/HTML/DAY-1/Containers-Tables-Lists/container-table-1.pdf";
function Htmlcss() {
	// console.log(pdfOne, "pdfone");

	const iFram = [
		{
			id: 1,
			ifram:
				"https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Best-practices/Slides%20(html-1-best-practices).pdf",
			ifram2:
				"https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Best-practices/container-table-1.pdf",
			title: "Best Practices",
		},
		{
			id: 2,
			ifram:
				"https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Containers-tables-lists/Slides-(html-1-containers-tables-and-lists).pdf",
			ifram2:
				"https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Containers-tables-lists/container-table1.pdf",
			title: "Containers-Tables-Lists",
		},
		{
			id: 3,
			ifram:
				"https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Introduction-to-html/Slides-(html-1-introduction-to-html).pdf",
			ifram2:
				"https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Introduction-to-html/Html-web-accessibility-introduction-to-html.pdf",
			title: "Introduction to HTML",
		},
	];
	const iFrame = [
		{
			id: 4,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-1/Restaurant-menu-activity/Thai-activity.pdf",
			title: "Restaurant Menu Activity",
		},
		{
			id: 5,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-1/workbook-day1-quiz-too/workbook-day-1.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-1/workbook-day1-quiz-too/Introduction-to-html-basics-best-practices-quiz-(html-1-quizzes).pdf",
			title: "workbook DAY1- QUIZ,too",
		},
	];
	const iFramtwo = [
		{
			id: 6,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Audio-video-iframes/html-web-accessibility-html-media.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Audio-video-iframes/Slides-(html-2-audio-video-and-iframes).pdf",
			title: "Audio, Video & Iframes",
		},
		{
			id: 7,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Iframes-embeds-subtitle-tracks/Html-web-accessibility-bird-songs.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Iframes-embeds-subtitle-tracks/Slides-(html-2-iframes-embeds-and-subtitle-tracks).pdf",
			title: "Iframes, Embeds, & Subtitle Tracks",
		},
		{
			id: 8,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Introduction-to-forms/Html-web-accessibility-forms.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Introduction-to-forms/Slides-(html-2-introduction-to-forms).pdf",
			title: "Introduction to Forms",
		},
	];
	const iFramthree = [
		{
			id: 9,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Validations-constraints/Html-web-accessibility-forms.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Validations-constraints/Slides-(html-2-validations-and-constraints).pdf",
			title: "Validations & Constraints",
		},
		{
			id: 10,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Workbook-day2-quiz/Module-02-workbook.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-2/Workbook-day2-quiz/The-html-media-forms-quiz2.pdf",
			title: "Workbook-day2, Quiz",
		},
	];
	const iFramfour = [
		{
			id: 11,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Change-for-the-better/Html-web-accessibility-semantic-html-web-accessibility.pdf",
			title: "Change for the Better",
		},
		{
			id: 12,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Html-layout-recap/Html-web-accessibility-html-review.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Html-layout-recap/Slides-(html-3-html-layouts-recap).pdf",
			title: "HTML Layout Recap",
		},
		{
			id: 13,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Practical-changes-for-accessible-sites/Html-web-accessibility-semantic-html-web-accessibility.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Practical-changes-for-accessible-sites/Slides-(html-3-practical-changes-for-accessible-sites).pdf",
			title: "Practical Changes for Accessible Sites",
		},
	];
	const iFramfive = [
		{
			id: 14,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/The-necessity-of-accessibility/Slides-(html-3-the-necessity-of-accessibility).pdf",
			title: "The Necessity of Accessibility",
		},
		{
			id: 15,
			ifram:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Work-book3-test3/Module-03-workbook.pdf",
			ifram2:
				"https://flowpaper.com/flipbook//https://developer-ourbase-camp.com/pdfs/HTML/Day-3/Work-book3-test3/The-semantic-html-web-accessibility-quiz-part-of-the-html-3-quizzes-series-focuses-on-the-concepts-of-semantic-html-and-web-accessibility.pdf",
			title: "WorkBook3,Test3",
		},
	];
	// console.log(iFram, "iFramiFram");
	return (
		<div>
			{/* <!--Breadcrumb--> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Kryp.ai">Kryp.ai</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Training">Training</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Html + Css
										</li>
									</ol>
								</nav>
							</section>

							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>

			{/* <!--End Breadcrumb-->


<!-- Javascript-Section1 --> */}
			<section className="javascript_sec1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div
									className="frame_contentBox scrollBar"
									style={{
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									<div className="row">
										<div className="col-12 col-md-8 col-lg-6 mb-4">
											<div className="headingBg mb-3">
												<label className="subHeading font20">
													come into our space to see to new
												</label>
											</div>
											<p className="Heading font30 my-3">HTML courses</p>
											<div className="courses-tabs-wrapper">
												<ul class="nav nav-tabs border-0" role="tablist">
													<li class="nav-item">
														<a
															class="nav-link active"
															data-bs-toggle="tab"
															href="#home"
														>
															DAY 1
														</a>
													</li>
													<li class="nav-item">
														<a
															class="nav-link"
															data-bs-toggle="tab"
															href="#menu1"
														>
															DAY 2
														</a>
													</li>
													<li class="nav-item">
														<a
															class="nav-link"
															data-bs-toggle="tab"
															href="#menu2"
														>
															DAY 3
														</a>
													</li>
												</ul>
												<div class="tab-content">
													<div id="home" class="container tab-pane active">
														<div class="javascript_sec3 mt-3">
															<div class="row">
																<div class="col-12 col-md-6 col-lg-6">
																	<div class="javascript_sec3_wrap">
																		<p class="font18">3-Month plan</p>
																		<ul className="p-0">
																			{iFram?.map((item, i) => {
																				return (
																					<li key={i}>
																						<CustomModal
																							id={item?.id}
																							title={item?.title}
																							iframeSrc={item?.ifram}
																							iframe2={item?.ifram2}
																						/>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</div>
																<div class="col-12 col-md-6 col-lg-6">
																	<div class="javascript_sec3_wrap">
																		<p class="font18">5-Month plan</p>
																		<ul className="p-0">
																			{iFrame?.map((item, i) => {
																				return (
																					<li key={i}>
																						<CustomModal
																							id={item?.id}
																							title={item?.title}
																							iframeSrc={item?.ifram}
																							iframe2={item?.ifram2}
																						/>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div id="menu1" class="container tab-pane fade">
														<div class="javascript_sec3 mt-3">
															<div class="row">
																<div class="col-12 col-md-6 col-lg-6">
																	<div class="javascript_sec3_wrap">
																		<p class="font18">3-Month plan</p>
																		<ul className="p-0">
																			{iFramtwo?.map((item, i) => {
																				return (
																					<li key={i}>
																						<CustomModal
																							id={item?.id}
																							title={item?.title}
																							iframeSrc={item?.ifram}
																							iframe2={item?.ifram2}
																						/>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</div>
																<div class="col-12 col-md-6 col-lg-6">
																	<div class="javascript_sec3_wrap">
																		<p class="font18">5-Month plan</p>
																		<ul className="p-0">
																			{iFramthree?.map((item, i) => {
																				return (
																					<li key={i}>
																						<CustomModal
																							id={item?.id}
																							title={item?.title}
																							iframeSrc={item?.ifram}
																							iframe2={item?.ifram2}
																						/>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div id="menu2" class="container tab-pane fade">
														<div class="javascript_sec3 mt-3">
															<div class="row">
																<div class="col-12 col-md-6 col-lg-6">
																	<div class="javascript_sec3_wrap">
																		<p class="font18">3-Month plan</p>
																		<ul className="p-0">
																			{iFramfour?.map((item, i) => {
																				return (
																					<li key={i}>
																						<CustomModal
																							id={item?.id}
																							title={item?.title}
																							iframeSrc={item?.ifram}
																							iframe2={item?.ifram2}
																						/>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</div>
																<div class="col-12 col-md-6 col-lg-6">
																	<div class="javascript_sec3_wrap">
																		<p class="font18">5-Month plan</p>
																		<ul className="p-0">
																			{iFramfive?.map((item, i) => {
																				return (
																					<li key={i}>
																						<CustomModal
																							id={item?.id}
																							title={item?.title}
																							iframeSrc={item?.ifram}
																							iframe2={item?.ifram2}
																						/>
																					</li>
																				);
																			})}
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4 col-lg-6 mb-4">
											<div className="mission_ryt_sec1">
												<img
													src={`${
														process.env.PUBLIC_URL +
														"assets/images/html-css.jpg"
													}`}
													className="img-fluid"
													alt="planet"
												/>
											</div>

											{/* <CustomModal title="abcModal" href="">
												<iframe
													src="https://publuu.com/flip-book/296882/689936/page/1?embed"
													width="100%"
													height="500"
													scrolling="yes"
													frameborder="0"
													allowfullscreen=""
													allow="clipboard-write"
													class="publuuflip"
												></iframe>
											</CustomModal> */}
										</div>
									</div>
									{/* <!-- HTML-Css Row 2 --> */}
									<div className="html_css_sec2 mt-4">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec3_wrap">
													<p className="font20">Self-Directed</p>
													<ul>
														<li>HTML & CSS – Self-Directed</li>
														<li>-Help With 2 Personal Projects</li>
														<li>-Get To Know Other classNamemates</li>
														<li>-Help With Resume And Interview Prep.</li>
														<li>-Unlimited Email And Chat Q&A.</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="border_padding javascript_sec3_wrap">
													<p className="font20">Live-classNamees</p>
													<ul>
														<li>HTML & CSS -Live classNamees</li>
														<li>-Everything From The Self-Directed Plan</li>
														<li>
															- 8 Weeks Of Live classNamees (2 Hrs. Per Week )
														</li>
														<li>- 6 Hours Of Dedicated Live Q&A. </li>
														<li>-Access To All Future Videos And Code </li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- HTML-Css Row 2 -->
						<!-- Html-Css Row 3 --> */}
									<div className="html_css_sec3 mt-5">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="headingBg">
													<label className="subHeading font20">
														come into our space to see to new
													</label>
												</div>
												<p className="Heading font30 my-3">
													When you will learn
												</p>
												<p className="font14 mt-2">
													This Course Covers The Fundamentals Of HTML & CSS. It
													Is The Best Way To Learn HTML & CSS In 2022. The
													Entire Course Was Re-Written In 2021. The Lessons &
													Challenges Will Guide You Through These Topics Step By
													Step And Its Project-Based Approach Will Help You
													Reinforce These Concepts. HTML And CSS
												</p>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="html_css_sec3_li">
													<ul>
														<li>Introduction To HTML</li>
														<li>Introduction To HTML Overview</li>
														<li>Getting Started With HTML</li>
														<li>What's In The Head? Metadata In HTML</li>
														<li>HTML Text Fundamentals</li>
														<li>Creating Hyperlinks</li>
														<li>Advanced Text Formatting</li>
														<li>Document And Website Structure</li>
														<li>Debugging HTML</li>
														<li>Assessment: Marking Up A Letter</li>
														<li>Assessment: Structuring A Page Of Content</li>
														<li>Multimedia And Embedding</li>
														<li>HTML Tables</li>
														<li>CSS — Styling The Web</li>
														<li>CSS First Steps</li>
														<li>CSS Building Blocks</li>
														<li>Styling Text</li>
														<li>CSS Layout</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Html-Css Row 3 --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Htmlcss;
