import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="d-flex justify-content-between footerWrap">
                        <div className="footerLyt_item">
                            <ul className="d-flex">
                                <li><a href="mailto:frankcervantes@me.com">frankcervantes@me.com</a></li>
                                <li><a href="tel:+9494660737">9494660737</a></li>
                            </ul>
                        </div>
                        <div className="footerRyt_item">
                            <ul className="d-flex">
                                <li><Link to="./Privacy">Privacy Policy</Link></li>
                                <li><Link to="./Termconditions">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer