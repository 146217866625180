import React from "react";
import { Link } from "react-router-dom";

function Termconditions() {
	return (
		<div>
			{/* <!--Breadcrumb--> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Teams and conditions
										</li>
									</ol>
								</nav>
							</section>

							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>

			{/* <!--End Breadcrumb--> */}

			{/* <!-- SpaceIn-Misson-Section --> */}
			<section className="SpaceIn_Misson_sec p_t_sec">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div className="frame_contentBox scrollBar scroll_adjust">
									<div className="container">
										<div className="row ">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="terms_main text-center">
													<div className="headingBg mb-3">
														<label className="subHeading font25">
															come into our space to see to new
														</label>
													</div>
													<p className="Heading font30 m-auto">
														TERMS AND CONDITIONS
													</p>
												</div>
												<div className="peraBox mt-4">
													<p className="font14 terms_content">
														{/* Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Pellentesque Habitant Morbi Tristique Senectus Et Netus Et. Tellus At Urna Condimentum Mattis. Ultricies Integer Quis Auctor Elit Sed Vulputate Mi Sit Amet. Sagittis Nisl Rhoncus Mattis Rhoncus Urna Neque Viverra. Convallis Convallis Tellus Id Interdum Velit Laoreet Id Donec. Sit Amet Facilisis Magna Etiam Tempor Orci Eu. Scelerisque Felis Imperdiet Proin Fermentum Leo Vel Orci. Sed Elementum Tempus Egestas Sed. Velit Scelerisque In Dictum Non Consectetur A Erat Nam At. Aliquam Etiam Erat Velit Scelerisque In Dictum. Odio Eu Feugiat Pretium Nibh Ipsum Consequat Nisl Vel Pretium. Eu Sem Integer Vitae Justo Eget Magna. Montes Nascetur Ridiculus Mus Mauris Vitae Ultricies Leo. Integer Malesuada Nunc Vel Risus Commodo Viverra Maecenas. Ut Porttitor Leo A Diam Sollicitudin. Tincidunt Praesent Semper Feugiat Nibh Sed. Commodo Odio Aenean Sed Adipiscing Diam Donec Adipiscing Tristique. Quis Enim Lobortis Scelerisque Fermentum. */}
														Welcome to Kryp.AI. We offer comprehensive training
														in programming languages, stock trading techniques,
														and various services related to Non-fungible Tokens
														(NFTs).
														<br />
														<br />
														<br />
														Users may share content. We reserve the right to
														moderate and use this content for marketing
														purposes, ensuring a safe and respectful community.
														<br />
														<br />
														<br />
														Our privacy practices are detailed in our Privacy
														Policy, linked here, underscoring our commitment to
														protecting your data.
														<br />
														<br />
														<br />
														Our website may contain links to third-party
														websites. We do not endorse these sites and are not
														responsible for their content.
														<br />
														<br />
														<br />
														All disputes will be governed by the laws of our
														jurisdiction, with arbitration as the preferred
														dispute resolution method.
														<br />
														<br />
														<br />
														For any inquiries or concerns, please contact us
														through the provided channels. Your feedback is
														valued and important to us.
														<br />
														<br />
														<br />
														We provide various training and informational
														content. We do not guarantee the accuracy or
														completeness of this content and limit our liability
														accordingly.
														<br />
														<br />
														<br />
														All content and materials on our site are either our
														property or used with permission. Respect for
														intellectual property rights is paramount.
														<br />
														<br />
														<br />
														We reserve the right to amend these terms and will
														notify users of any significant changes. Regular
														updates ensure compliance and relevance.
														<br />
														<br />
														<br />
														Our services comply with applicable laws and
														regulations, ensuring a lawful and ethical operating
														environment.
														<br />
														<br />
														<br />
														Users must use our services lawfully and
														responsibly. Inappropriate use may result in account
														termination and legal action.
														<br />
														<br />
														<br />
														These terms represent the entire agreement between Kryp.AI and its users. If any part is invalid, the remainder still applies.
													</p>
													{/* <p className="font14 mt-4">
											Non Odio Euismod Lacinia At Quis Risus. Rhoncus Est Pellentesque Elit Ullamcorper Dignissim Cras Tincidunt. Lectus Mauris Ultrices Eros In Cursus Turpis. Leo In Vitae Turpis Massa Sed Elementum. Aliquet Bibendum Enim Facilisis Gravida. Eget Velit Aliquet Sagittis Id. Posuere Sollicitudin Aliquam Ultrices Sagittis Orci A Scelerisque Purus. Phasellus Vestibulum Lorem Sed Risus Ultricies Tristique Nulla Aliquet Enim. Mauris Sit Amet Massa Vitae Tortor Condimentum Lacinia Quis. Pretium Nibh Ipsum Consequat Nisl Vel Pretium Lectus Quam. Purus In Mollis Nunc Sed. Viverra Suspendisse Potenti Nullam Ac Tortor Vitae Purus Faucibus. Vel Orci Porta Non Pulvinar Neque Laoreet Suspendisse Interdum. Sodales Ut Etiam Sit Amet Nisl Purus In Mollis Nunc. In Nisl Nisi Scelerisque Eu Ultrices Vitae Auctor Eu.
										</p> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End-SpaceIn-Section1 --> */}
		</div>
	);
}

export default Termconditions;
