import React, { useState } from "react";
import { Link } from "react-router-dom";

function Training() {
	const [activeTab, setActiveTab] = useState("Galaxical Space");
	const [categories, setCategories] = useState([
		"Galaxical Space",
		"Bots",
		"Training",
	]);

	// Function to handle tab click
	const handleTabClick = (category) => {
		setActiveTab(category);
	};

	return (
		<div>
			{/* <!-- BreadCrumb & Heading --> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Spacedin">Spaced in</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Training
										</li>
									</ol>
								</nav>
							</section>
							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- BreadCrumb & Heading  --> */}

			{/* Mission Section1 */}
			<section class="mission_sec1">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-12 col-lg-12">
							<div class="frame">
								<div
									class="frame_contentBox scrollBar"
									style={{
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									<div class="row">
										<div class="col-12 col-md-8 col-lg-8">
											<div class="headingBg">
												<label class="subHeading font20">
													come into our space to see to new
												</label>
											</div>
											<p class="Heading font30 my-3">
												Comprehensive Information Around Digital Transformation
											</p>
											<p class="font14 mt-5">
												Kryp.ai Is Your One-Stop Shop For Information About NFT
												And Metaverse. We Have The Resources You Need To
												Navigate Through The Transformation That’s Happening In
												The Digital Sphere.
											</p>
										</div>
										<div class="col-12 col-md-4 col-lg-4">
											<div class="mission_ryt_sec1">
												<img
													src="assets/images/astronaut.png"
													class="img-fluid"
													alt="planet"
												/>
											</div>
										</div>
									</div>
									{/* Training Row 2 */}
									<div class="training_sec2">
										<div class="row">
											<div class="col-12 col-md-12 col-lg-12">
												<div class="tabBox">
													<ul
														class="nav nav-pills justify-content-center my-4"
														id="pills-tab"
														role="tablist"
													>
														{categories.map((category) => (
															<li
																class="nav-item"
																role="presentation"
																key={category}
															>
																<button
																	class={`nav-link ${activeTab === category ? "active" : ""
																		}`}
																	onClick={() => handleTabClick(category)}
																>
																	{category}
																</button>
															</li>
														))}
													</ul>
													<div class="tab-content" id="pills-tabContent">
														{/* Tab1 */}
														<div
															class={`tab-pane fade ${activeTab === "Galaxical Space"
																	? "show active"
																	: ""
																}`}
															id="traingTab1"
															role="tabpanel"
															aria-labelledby="traingTab1-tab"
														>
															<div class="img_sec">
																<div class="row">
																	{/* col */}
																	<div class="col-12 col-sm-6 col-md-3 col-lg-3">
																		<div class="imgBox">
																			<img
																				src="assets/images/training-img1.png"
																				class="img-fluid"
																				alt="mission-img1"
																			/>
																			<div class="absSec2">
																				{/* <p class="font14">Asteroids</p> */}
																				<p class="font14">Galactical Space</p>
																			</div>
																		</div>
																	</div>
																	{/* col */}
																	<div class="col-12 col-sm-6 col-md-3 col-lg-3">
																		<div class="imgBox">
																			<img
																				src="assets/images/training-img2.png"
																				class="img-fluid"
																				alt="mission-img1"
																			/>
																			<div class="absSec2">
																				<p class="font14">Galactical Space</p>
																			</div>
																		</div>
																	</div>
																	{/* col */}
																	<div class="col-12 col-sm-6 col-md-3 col-lg-3">
																		<div class="imgBox">
																			<img
																				src="assets/images/training-img3.png"
																				class="img-fluid"
																				alt="mission-img1"
																			/>
																			<div class="absSec2">
																				<p class="font14">Galactical Space</p>
																			</div>
																		</div>
																	</div>
																	{/* col */}
																	<div class="col-12 col-sm-6 col-md-3 col-lg-3">
																		<div class="imgBox">
																			<img
																				src="assets/images/training-img4.png"
																				class="img-fluid"
																				alt="mission-img1"
																			/>
																			<div class="absSec2">
																				<p class="font14">Galactical Space</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{/* Tab2 */}
														<div
															class={`tab-pane fade ${activeTab === "Bots" ? "show active" : ""
																}`}
															id="trainingTab2"
															role="tabpanel"
															aria-labelledby="trainingTab2-tab"
														>
															<div>
																<div class="container">
																	<div class="row">
																		<div class="col-12 col-md-12 col-lg-12">
																			<div class=" botFrame">
																				<div
																					class="frame_contentBox"
																					style={{
																						backgroundColor: "black",
																						padding: 20,
																						marginTop: 0,
																					}}
																				>
																					<div class="row text-center">
																						<div class="col-12 col-md-12 col-lg-12">
																							{/* <div class="headingBg">
																								<p class="subHeading font25">
																									come into our space to see to
																									new
																								</p>
																							</div> */}
																							<p class="Heading font30 mb-3">
																							Under Construction 
																							</p>
																						</div>
																					</div>
																					{/* <p class="font14 mb-3">
																						Lorem ipsum dolor sit amet,
																						consectetur adipisicing elit, sed do
																						eiusmod tempor incididunt ut labore
																						et dolore magna aliqua. Ut enim ad
																						minim veniam, quis nostrud
																						exercitation ullamco laboris nisi ut
																						aliquip ex ea commodo consequat.
																						Duis aute irure dolor in
																						reprehenderit in voluptate velit
																						esse cillum dolore eu fugiat nulla
																						pariatur. Excepteur sint occaecat
																						cupidatat non proident, sunt in
																						culpa qui officia deserunt mollit
																						anim id est laborum.
																					</p> */}
																					<img
																						src="assets/images/MaskGroup1.png"
																						alt="planet"
																						style={{
																							width: "100%",
																							position: "absolutes",
																						}}
																					/>
																					{/* <p class="font14 mt-3">
																						Lorem ipsum dolor sit amet,
																						consectetur adipisicing elit, sed do
																						eiusmod tempor incididunt ut labore
																						et dolore magna aliqua. Ut enim ad
																						minim veniam, quis nostrud
																						exercitation ullamco laboris nisi ut
																						aliquip ex ea commodo consequat.
																						Duis aute irure dolor in
																						reprehenderit in voluptate velit
																						esse cillum dolore eu fugiat nulla
																						pariatur. Excepteur sint occaecat
																						cupidatat non proident, sunt in
																						culpa qui officia deserunt mollit
																						anim id est laborum.
																					</p> */}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{/* Tab3 */}
														<div
															class={`tab-pane fade ${activeTab === "Training" ? "show active" : ""
																}`}
															id="trainingTab3"
															role="tabpanel"
															aria-labelledby="trainingTab3-tab"
														>
															<div class="trainigTab_sec">
																<div class="row text-center">
																	<div class="col-12 col-md-12 col-lg-12">
																		<div class="headingBg">
																			<label class="subHeading font25">
																				come into our space to see to new
																			</label>
																		</div>
																		<p class="Heading font30 mb-3 m-auto">
																			Training
																		</p>
																	</div>
																</div>
																<div class="img_sec">
																	<div
																		class="row"
																		style={{
																			display: "flex",
																			justifyContent: "center",
																		}}
																	>
																		{/* col */}
																		<div
																			class="col-12 col-sm-6 col-md-4 col-lg-4"
																			style={{
																				padding: "10px",
																				width: "auto",
																			}}
																		>
																			<div
																				class="imgBox trainingTab3"
																				style={{
																					width: "235px",
																					height: "250px",
																					padding: "20px",
																				}}
																			>
																				<img
																					src="assets/images/training-tab3-img1.png"
																					//class="img-fluid"
																					alt="mission-img1"
																					style={{
																						position: "cover",
																						width: "200px",
																					}}
																				/>
																				<div
																					class="absSec2"
																					style={{
																						marginLeft: "6px",
																					}}
																				>
																					<Link to="/Htmlcss">
																						<span>
																							Select Option{" "}
																							<i class="fa-solid fa-arrow-right"></i>
																						</span>
																					</Link>
																				</div>
																				<div
																					class="absSec3"
																					style={{
																						height: "50px",
																						marginRight: "16px",
																						marginLeft: "20px",
																						padding: "0px",
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}
																				>
																					<div>
																						<p
																							class="font14"
																							style={{
																								margin: "0px",
																							}}
																						>
																							TRAINING 1
																						</p>
																						<p
																							class="font20"
																							style={{
																								margin: "0px",
																							}}
																						>
																							HTML + CSS
																						</p>
																					</div>
																				</div>
																			</div>
																		</div>
																		{/* col */}
																		{/* <div
																			class="col-12 col-sm-6 col-md-4 col-lg-4"
																			style={{
																				padding: "10px",
																				width: "auto",
																			}}
																		>
																			<div
																				class="imgBox trainingTab3"
																				style={{
																					width: "235px",
																					height: "250px",
																					padding: "20px",
																				}}
																			>
																				<img
																					src="assets/images/training-tab3-img3.png"
																					//class="img-fluid"
																					alt="mission-img1"
																					style={{
																						position: "cover",
																						width: "200px",
																					}}
																				/>
																				<div
																					class="absSec2"
																					style={{
																						marginLeft: "6px",
																					}}
																				>
																					<Link to="/Python">
																						<span>
																							Select Option{" "}
																							<i class="fa-solid fa-arrow-right"></i>
																						</span>
																					</Link>
																				</div>
																				<div
																					class="absSec3"
																					style={{
																						height: "50px",
																						marginRight: "16px",
																						marginLeft: "20px",
																						padding: "0px",
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}
																				>
																					<div>
																						<p
																							class="font14"
																							style={{
																								margin: "0px",
																							}}
																						>
																							TRAINING 2
																						</p>
																						<p
																							class="font20"
																							style={{
																								margin: "0px",
																							}}
																						>
																							PYTHON
																						</p>
																					</div>
																				</div>
																			</div>
																		</div> */}
																		{/* col */}
																		<div
																			class="col-12 col-sm-6 col-md-4 col-lg-4"
																			style={{
																				padding: "10px",
																				width: "auto",
																			}}
																		>
																			<div
																				class="imgBox trainingTab3"
																				style={{
																					width: "235px",
																					height: "250px",
																					padding: "20px",
																				}}
																			>
																				<img
																					src="assets/images/training-tab3-img2.png"
																					//class="img-fluid"
																					alt="mission-img1"
																					style={{
																						position: "cover",
																						width: "200px",
																					}}
																				/>
																				<div
																					class="absSec2"
																					style={{
																						marginLeft: "6px",
																					}}
																				>
																					<Link to="/Javacript">
																						<span>
																							Select Option{" "}
																							<i class="fa-solid fa-arrow-right"></i>
																						</span>
																					</Link>
																				</div>
																				<div
																					class="absSec3"
																					style={{
																						height: "50px",
																						marginRight: "16px",
																						marginLeft: "20px",
																						padding: "0px",
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}
																				>
																					<div>
																						<p
																							class="font14"
																							style={{
																								margin: "0px",
																							}}
																						>
																							TRAINING 2
																						</p>
																						<p
																							class="font20"
																							style={{
																								margin: "0px",
																							}}
																						>
																							JAVASCRIPT
																						</p>
																					</div>
																				</div>
																			</div>
																		</div>
																		{/* col */}
																		<div
																			class="col-12 col-sm-6 col-md-4 col-lg-4"
																			style={{
																				padding: "10px",
																				width: "auto",
																			}}
																		>
																			<div
																				class="imgBox trainingTab3"
																				style={{
																					width: "235px",
																					height: "250px",
																					padding: "20px",
																				}}
																			>
																				<img
																					src="assets/images/uiux1.png"
																					//class="img-fluid"
																					alt="mission-img1"
																					style={{
																						position: "cover",
																						width: "200px",
																					}}
																				/>
																				<div
																					class="absSec2"
																					style={{
																						marginLeft: "6px",
																					}}
																				>
																					<Link to="/uiux">
																						<span>
																							Select Option{" "}
																							<i class="fa-solid fa-arrow-right"></i>
																						</span>
																					</Link>
																				</div>
																				<div
																					class="absSec3"
																					style={{
																						height: "50px",
																						marginRight: "16px",
																						marginLeft: "20px",
																						padding: "0px",
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}
																				>
																					<div>
																						<p
																							class="font14"
																							style={{
																								margin: "0px",
																							}}
																						>
																							TRAINING 3
																						</p>
																						<p
																							class="font20"
																							style={{
																								margin: "0px",
																							}}
																						>
																							UI/UX
																						</p>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div
																			class="col-12 col-sm-6 col-md-4 col-lg-4"
																			style={{
																				padding: "10px",
																				width: "auto",
																			}}
																		>
																			<div
																				class="imgBox trainingTab3"
																				style={{
																					width: "235px",
																					height: "250px",
																					padding: "20px",
																				}}
																			>
																				<img
																					src="assets/images/css.png"
																					//class="img-fluid"
																					alt="mission-img1"
																					style={{
																						position: "cover",
																						width: "200px",
																					}}
																				/>
																				<div
																					class="absSec2"
																					style={{
																						marginLeft: "6px",
																					}}
																				>
																					<Link to="/css">
																						<span>
																							Select Option{" "}
																							<i class="fa-solid fa-arrow-right"></i>
																						</span>
																					</Link>
																				</div>
																				<div
																					class="absSec3"
																					style={{
																						height: "50px",
																						marginRight: "16px",
																						marginLeft: "20px",
																						padding: "0px",
																						display: "flex",
																						alignItems: "center",
																						justifyContent: "center",
																					}}
																				>
																					<div>
																						<p
																							class="font14"
																							style={{
																								margin: "0px",
																							}}
																						>
																							TRAINING 4
																						</p>
																						<p
																							class="font20"
																							style={{
																								margin: "0px",
																							}}
																						>
																							Css
																						</p>
																					</div>
																				</div>
																			</div>
																		</div>
																		{/* col */}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* End-Training Row 2 */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End-Mission-Section1 */}
		</div>
	);
}

export default Training;
