import React from "react";
import { Link } from "react-router-dom";
import CustomModal2 from "../Components/CustomModal/CustomeModal2"
function Javacript() {
	const javascriptData = [
		{
			id: 1,
			threeMonth: [
				{
					id: 1,
					title: "Boolean Expressions & Comparisons",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Boolean-expressions-comparisons/Javascript-frontend-webdevelopment-controlflow.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Boolean-expressions-comparisons/Slides-(js1-boolean-expressions-and-comparisons-).pdf",
						},
					],
				},
				{
					id: 2,
					title: "Conditional Statements",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Conditional-statements/Javascript-front-end-webdevelopment-controlflow.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Conditional-statements/Javascript-front-end-webdevelopment-rock-paper-scissors.pdf",
						},
						{
							id: 3,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Conditional-statements/Slides-(js1-conditional-statements).pdf",
						},
						{
							id: 4,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Conditional-statements/carnival.pdf",
						},
					],
				},
				{
					id: 3,
					title: "Front-End Development in Context",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Front-end-development-in-context/Slides-(js1-front-end-development-in-context).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 4,
					title: "Primitive Types & Objects",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Primitive-types-objects/Javascript-frontend-webdevelopment-course.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/Primitive-types-objects/Slides-(js1-primitive-types-and-objects).pdf",
						},
					],
				},
				{
					id: 5,
					title: "quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day1/quiz/Welcome-to-the-quiz-javascript-basics-(js1-quizzes).pdf",
						},
					],
				},
			],
			day: "DAY 1",
		},
		{
			id: 2,
			threeMonth: [
				{
					id: 6,
					title: "Defining & invoking functions",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/Defining-invoking-functions/Javascript-front-end-webdevelopment-functions.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/Defining-invoking-functions/Slides-(js2-defining-and-invoking-functions).pdf",
						},
					],
				},
				{
					id: 7,
					title: "loops",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/loops/Javascript-front-end-webdevelopment-controlflow.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/loops/Slides-(js2-loops).pdf",
						},
					],
				},
				{
					id: 9,
					title: "Parameters,Arguments, & Return Values",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/Parameters-arguments-return-values/Javascript-front-end-webdevelopment-functions.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/Parameters-arguments-return-values/Slides-(js2-parameters-arguments-and-return-values).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 10,
					title: "Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/Quiz/Welcome-to-the-controlflowquiz.pdf",
						},
					],
				},
				{
					id: 11,
					title: "Web Game Part I",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day2/Webgame-partI/Javascript-frontend-webdevelopment-functions.pdf",
						},
					],
				},
			],
			day: "DAY 2",
		},
		{
			id: 3,
			threeMonth: [
				{
					id: 12,
					title: "Callback Functions",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day3/Callback-functions/Slides-(js3-callback-functions).pdf",
						},
					],
				},
				{
					id: 13,
					title: "Functions & Scope",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day3/Functions-scope/Javascript-frontend-webdevelopment-functions.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day3/Functions-scope/Slides-(js3-functions-and-scope).pdf",
						},
					],
				},
				{
					id: 14,
					title: "Functions As First-Class Citizen",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day3/Functions-ass-first-classcitizen/Javascript-front-end-webdevelopment-functions.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day3/Functions-ass-first-classcitizen/Slides-(js3-functions-as-first-class-citizens).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 15,
					title: "Web Game Part IV",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day3/Webgame-partIV/Javascript-frontend-webdevelopment-functions.pdf",
						},
					],
				},
			],
			day: "DAY 3",
		},
		{
			id: 4,
			threeMonth: [
				{
					id: 16,
					title: "Array Iterators",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day4/Array-iterators/Slides-(js4-array-iterators-callback-functions-in-action).pdf",
						},
					],
				},
				{
					id: 17,
					title: "OOP Concepts",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day4/Oop-concepts/Slides-(js4-oop-concepts).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 18,
					title: "Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day4/Quiz/Clickhere-for-thetest.pdf",
						},

					],
				},
			],
			day: "DAY 4",
		},
		{
			id: 5,
			threeMonth: [
				{
					id: 19,
					title: "Class inheritance",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Class-inheritance/Sd05-Javascript-front-end-webdevelopment-Objectoriented-programming-with-Javascript.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Class-inheritance/Slides-(js5-class-inheritance).pdf",
						},
					],
				},
				{
					id: 20,
					title: "Class Practice",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Class-practice/Sd05-JavaScript-frontend-webdevelopment-objectoriented-Programming-with-Javascript.pdf",
						},
					],
				},
				{
					id: 21,
					title: "Classes, Properties, and Methods",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Classes-properties-and-methods/Activity-classes-of-chance.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Classes-properties-and-methods/Slides-(js5-classes-properties-and-methods).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 22,
					title: "Inheritance Practice",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Inheritance-practice/Activity-array-automotive-partII.pdf",
						},
					],
				},
				{
					id: 23,
					title: "Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day5/Quiz/Javascript-(js5-quizzes).pdf",
						},
					],
				},
			],
			day: "DAY 5",
		},
		{
			id: 6,
			threeMonth: [
				{
					id: 24,
					title: "Asynchrony with Callback Functions",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day6/Asynchrony-with-callback-functions/Activity-webgame-PartV.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day6/Asynchrony-with-callback-functions/Slides-(js6-asynchrony-with-callback-functions).pdf",
						},
					],
				},
				{
					id: 25,
					title: "Asynchrony with Promises",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day6/Asynchrony-with-promises/Slides-(js6-asynchrony-with-promises).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 26,
					title: "introduction to asynchrony",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day6/Introduction-to-asynchrony/Slides(js6-asynchrony-with-promises).pdf",
						},
					],
				},
				{
					id: 27,
					title: "practicing-promises",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day6/Practicing-promises/Activity-webgame-partVI.pdf",
						},
					],
				},
			],
			day: "DAY 6",
		},
		{
			id: 7,
			threeMonth: [
				{
					id: 28,
					title: "Asynchrony with Async_Await",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day7/Asynchrony-with-async-await/Slides-(js7-asynchrony-with-async-await).pdf",
						},
					],
				},
				{
					id: 29,
					title: "Concurrency with Promise.all",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day7/Concurrency-with-promise-all/Slides-(js7-concurrency-with-promise-all).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 30,
					title: "Practicing Async_await",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day7/Practicing-async-await/Activity-webgame-partVII.pdf",
						},
					],
				},
				{
					id: 31,
					title: "Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day7/Quiz/Introducing-the-quiz-asynchronous-javascript-(js7-quizzes).pdf",
						},
					],
				},
			],
			day: "DAY 7",
		},
		{
			id: 8,
			threeMonth: [
				{
					id: 32,
					title: "Creating Dynamic Content",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/Creating-dynamic-content/Javascript-frontend-webdevelopment-interactive-webpages.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/Creating-dynamic-content/Slides-(js8-creating-dynamic-content).pdf",
						},
					],
				},
				{
					id: 33,
					title: "Inline & External Scripts",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/Inline-external-scripts/Javascript-frontend-webdevelopment-inline-external-scripts.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/Inline-external-scripts/Slides-(js8-inline-and-external-scripts).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 34,
					title: "The DOM Tree",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/The-dom-tree/Javascript-front-end-webdevelopment-interactive-webpages.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/The-dom-tree/Slides-(js8-the-dom-tree).pdf",
						},
					],
				},
				{
					id: 35,
					title: "Working with Browser Events",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/Working-with-browser-events/Javascript-front-end-webdevelopment-interactive-webpages.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day8/Working-with-browser-events/slides-js8-working-with-browser-events.pdf",
						},
					],
				},
			],
			day: "DAY 8",
		},
		{
			id: 9,
			threeMonth: [
				{
					id: 36,
					title: "Event Bubbling & Propagation",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Event-bubbling-propagation/Eventbubbling-and-propagation.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Event-bubbling-propagation/Slides-(js9-event-bubbling-and-propagation).pdf",
						},
					],
				},
				{
					id: 37,
					title: "Performance",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Performance/Interactive-webpages-performance.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Performance/Slides-(js9-performance).pdf",
						},
					],
				},
				{
					id: 38,
					title: "Query Selector & jQuery Historical Overview",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/queryselector-jquery-historicaloverview/query-selector-jquery-historical-overview.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/queryselector-jquery-historicaloverview/slides-js9-query-selector-and-jquery-historical-overview.pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 39,
					title: "Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Quiz/Welcome-to-the-exciting-world-of-interactive-webpages.pdf",
						},
					],
				},
				{
					id: 40,
					title: "Regular Expressions",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Regular-expressions/Parsing-with-regular-expressions.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day9/Regular-expressions/Slides-(js9-regular-expressions).pdf",
						},
					],
				},
			],
			day: "DAY 9",
		},
		{
			id: 10,
			threeMonth: [
				{
					id: 41,
					title: "Fetch Practice",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Fetch-practice/Activity-building-an-admin-portal.pdf",
						},
					],
				},
				{
					id: 42,
					title: "Fetching Data From a Server",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Fetching-data-from-a-server/Activity-building-a-shared-counter-partI.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Fetching-data-from-a-server/Slides-(js10-fetching-data-from-a-server).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 43,
					title: "Sending Data to a Server",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Sending-data-to-a-server/Activity-building-a-shared-counter-partII.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Sending-data-to-a-server/Slides-(js10-sending-data-to-a-server).pdf",
						},
					],
				},
				{
					id: 44,
					title: "Thrid-Party Libraries & ChartJS",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Thrid-party-libraries-chartjs/Activity-stock-charts.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day10/Thrid-party-libraries-chartjs/Slides-(js10-third-party-libraries-and-chartjs).pdf",
						},
					],
				},
			],
			day: "DAY 10",
		},
		{
			id: 11,
			threeMonth: [
				{
					id: 45,
					title: "encapsulation",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day11/Encapsulation/Activity-encapsulation-practice.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day11/Encapsulation/Slides-(js11-encapsulation).pdf",
						},
					],
				},
				{
					id: 46,
					title: "Leaflet Library",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day11/Leaflet-library/Activity-building-an-interactive-map.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day11/Leaflet-library/Slides-(js11-leaflet-library).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 47,
					title: "Refactoring with Syntactic Sugar",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day11/Refactoring-with-syntactic-sugar/Slides-(js11-refactoring-with-syntactic-sugar).pdf",
						},
					],
				},
				{
					id: 48,
					title: "User Data",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day11/User-data/Activity-building-targeted-ads.pdf",
						},
					],
				},
			],
			day: "DAY 11",
		},
		{
			id: 12,
			threeMonth: [
				{
					id: 49,
					title: "JavaScript Development in Context",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day12/Javascript-development-in-context/Javascript-development-in-context.pdf",
						},
					],
				},
				{
					id: 50,
					title: "Making an Interactive Map Part I",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day12/Making-an-interactive-map-partI/Activity-making-an-interactive-map-partI.pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 51,
					title: "Making an Interactive Map Part II",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day12/Making-an-interactive-map-partII/Activity-making-an-interactive-map-partII.pdf",
						},
					],
				},
				{
					id: 52,
					title: "Making an Interactive Map Part III",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Javascript/Js-day12/Making-an-interactive-map-partIII/Activity-making-an-interactive-map-partIII.pdf",
						},
					],
				},
			],
			day: "DAY 12",
		},
	];
	return (
		<div>
			{/* <!--Breadcrumb--> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Kryp.ai">Kryp.ai</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Training">Training</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Javascript
										</li>
									</ol>
								</nav>
							</section>

							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>

			{/* <!--End Breadcrumb-->


<!-- Javascript-Section1 --> */}
			<section className="javascript_sec1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div
									className="frame_contentBox scrollBar"
									style={{
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									<div className="row align-items-center justify-content-center">
										<div className="col-12 col-md-8 col-lg-12 mb-4">
											<div className="headingBg mb-3 text-center">
												<label className="subHeading font20">
													come into our space to see to new
												</label>
											</div>
											<p className="Heading text-center font30 my-3">
												JavaScript courses
											</p>
											<div className="col-12 col-md-6 col-lg-6 m-auto">
												<div className="mission_ryt_sec1">
													<img
														src="assets/images/Javascript.jpg"
														className="img-fluid"
														alt="planet"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="tabs-upper pt-4">
										<div className="row">
											<div className="col-lg-12">
												<div className="courses-tabs-wrapper">
													<ul class="nav nav-tabs border-0" role="tablist">
														{javascriptData?.map((items, index) => (
															<li class="nav-item" key={index}>
																<a
																	class={`nav-link ${
																		items?.id === 1 ? "active" : ""
																	}`}
																	data-bs-toggle="tab"
																	href={`#${items?.id}`}
																>
																	{items?.day}
																</a>
															</li>
														))}
													</ul>
													<div class="tab-content">
														{javascriptData?.map((item, index) => (
															<div
																id={item?.id}
																key={index}
																class={`container tab-pane ${
																	item?.id === 1 ? "active" : ""
																}`}
															>
																<div class="javascript_sec3 mt-3">
																	<div class="row">
																		<div class="col-12 col-md-6 col-lg-4">
																			<div class="javascript_sec3_wrap">
																				<p class="font18">3-Month plan</p>
																				<ul className="p-0">
																					{item?.threeMonth?.map((data, i) => {
																						return (
																							<li key={i}>
																								<CustomModal2
																									id={data?.id}
																									title={data?.title}
																									iframeSrc={data?.iframes}
																								/>
																							</li>
																						);
																					})}
																				</ul>
																			</div>
																		</div>
																		<div class="col-12 col-md-6 col-lg-4">
																			<div class="javascript_sec3_wrap">
																				<p class="font18">5-Month plan</p>
																				<ul className="p-0">
																					{item?.fiveMonth?.map((datas, i) => {
																						return (
																							<li key={i}>
																								<CustomModal2
																									id={datas?.id}
																									title={datas?.title}
																									iframeSrc={datas?.iframes}
																								/>
																							</li>
																						);
																					})}
																				</ul>
																			</div>
																		</div>
																		<div class="col-12 col-md-6 col-lg-4"></div>
																	</div>
																</div>
															</div>
														))}
														{/* <div id="menu1" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramtwo?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramthree?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div> */}
														{/* <div id="menu2" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day4" class="container tab-pane ">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFram?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFrame?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day5" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramtwo?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramthree?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day6" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day7" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day8" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day9" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day10" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day11" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div>
														<div id="day12" class="container tab-pane fade">
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{iFramfour?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{iFramfive?.map((item, i) => {
																					return (
																						<li key={i}>
																							<CustomModal
																								id={item?.id}
																								title={item?.title}
																								iframeSrc={item?.ifram}
																								iframe2={item?.ifram2}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-4"></div>
																</div>
															</div>
														</div> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- Javascript Row 2 --> */}
									<div className="mission_sec2 javascript_sec2">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<p className="font20 text-center">
													<b>
														Eligibility Criteria for admissin to this caltech
														coding bootcamp,candidates:
													</b>
												</p>
												<ul className="javascript_row2_list">
													<li>
														{" "}
														are not required to have prior work experience
													</li>
													<li>
														{" "}
														at least 18 year and have a hight school diploma or
														equlvalent
													</li>
													<li> may have a non-programming beacground</li>
												</ul>
											</div>
										</div>
										<div className="abs_sec">
											<img
												src="assets/images/planet02.png"
												className="img-fluid"
												align="javascript_img2"
											/>
										</div>
									</div>
									{/* <!-- End-Mission Row 2 -->
						<!-- javascript Row 3 --> */}
									<div className="javascript_sec3 mt-3">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec3_wrap">
													<p className="font20">
														3-Month plan <br />
														Initial down payment
													</p>
													<ul>
														<li>1St $</li>
														<li>2Nd $$</li>
														<li>3Rd $$$</li>
														<li>Everything from the self-directed plan</li>
														<li>
															8 weeks of live classNameess (2 Hrs. per week)
														</li>
														<li>unlimited email and chat Q&A</li>
														<li>6 hours of dedlicated live Q&A</li>
														<li>Access to All future videos and code</li>
														<li>Help with 2 personal projects</li>
														<li>get to know other classNamemates</li>
														<li>help with resume and interview prep</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="border_padding javascript_sec3_wrap">
													<p className="font20">
														3-Month plan <br />
														Initial down payment
													</p>
													<ul>
														<li>1St $</li>
														<li>2Nd $$</li>
														<li>3Rd $$$</li>
														<li>4Th $$$$</li>
														<li>5Th $$$$$</li>
														<li>JAVASCRIPT-Live classNamees</li>
														<li>Everything From The Self-Directed Plan</li>
														<li>Access To All Future Videos And Code.</li>
														<li>
															10 Weeks Of Live classNamees (2 Hours Per Week)
														</li>
														<li>Help With 2 Personal Projects </li>
														<li>6Hours Of Dedicated Live Q&A </li>
														<li>Lifetime Access To Our Video Library </li>
														<li>Lifetime Access To Our Library Of Code </li>
														<li>Access To Cryppunkss Community </li>
														<li>
															Instructor Support Through Cryppunkss Community{" "}
														</li>
														<li>6 Hours Of Dedicated Live Q&A</li>
														<li>Lifetime Access To Our Video Library</li>
														<li>Lifetime Access To Our Library Of Code</li>
														<li>Access To Cryppunkss Community</li>
														<li>
															Instructor Support Through Cryppunkss Community
														</li>
														<li>
															Access To Weekly Discussion Hour Through
															Cryppunkss Community
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Javascript Row 3 -->
						<!-- Javascript Row 4 --> */}
									<div className="javascript_sec4 mt-5">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="headingBg">
													<label className="subHeading font20">
														come into our space to see to new
													</label>
												</div>
												<p className="Heading font30 my-3">
													When you will learn
												</p>
												<p className="font14 mt-2">
													This Course Covers The Fundamentals Of Javascript. It
													Is The Best Way To Learn Javascript In 2022. The
													Entire Course Was Re-Written In 2021. The Lessons &
													Challenges Will Guide You Through These Topics Step By
													Step And Its Project-Based Approach Will Help You
													Reinforce These Concepts.
												</p>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec4_li">
													<ul>
														<li>Arrays. -Advanced DOM</li>
														<li>Functons -Events</li>
														<li>Arrow Functions. -Forms</li>
														<li>Advanced Arrays - Package Managers</li>
														<li>Destructuring. - Module Bundlers</li>
														<li>Optional Chaining. - Modules</li>
														<li>Nullish Coalescing. - Dynamic Imports</li>
														<li>Objects - Ecmascrips</li>
														<li>Arrays Of Objects</li>
														<li>Advanced Control Flow</li>
														<li>Reduce</li>
														<li>classNamees</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec4_li">
													<ul>
														<li>Callbacks</li>
														<li>Asynchronous Logic</li>
														<li>Arrow Functions</li>
														<li>Prototypical Inheritance</li>
														<li>JSON</li>
														<li>Promises</li>
														<li>Fetch</li>
														<li>Work With Real Apis</li>
														<li>Lexical Scope</li>
														<li>Async/Await</li>
														<li>DOM Selection</li>
														<li>DOM Basics</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Javascript Row 4 --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Javacript;
