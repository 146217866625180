import React, { useEffect, useState } from 'react'
import { NftImagesApi } from '../../services/admin/NftImages'
import './search.css';
import { Link } from 'react-router-dom';

const Search = ({show=false, setOpenSearch = () => {}}) => {

    const [searchInput, setSearchInput] = useState('')
    const [searchList, setSearchList] = useState([])
    const [loading, setLoading] = useState(false)

    const searchFunc = async () => {
        if(searchInput && !loading)
        {
            setLoading(true)
            let res = await NftImagesApi.findImages({search: searchInput})
            setSearchList(res.nfts)
            setLoading(false)
        }
    }

    const arrayBufferToBase64 = (buffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
	
		for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
		}
        console.log(window.btoa(binary))
		return window.btoa(binary);
	};

    useEffect(() => {
        if(show)
        {
            document.querySelector('#search-popup')?.classList.remove('d-none')
            setTimeout(() => {
                document.querySelector('#search-popup')?.classList.remove('hide')
            }, 100);
        }
        else
        {
            document.querySelector('#search-popup')?.classList.add('hide')
            setTimeout(() => {
                document.querySelector('#search-popup')?.classList.add('d-none')
            }, 200);
        }
    }, [show])

    useEffect(() => {
        let _searchInput = searchInput
        setTimeout(() => {
            if(searchInput == _searchInput)
            {
                searchFunc()
            }
        }, 1000);
    }, [searchInput])

    return (
        <div>
            <div id="search-popup" className="d-none hide">
                <span id='search-close' onClick={() => setOpenSearch(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="text-white" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{width: "25px", height: '25px'}}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </span>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />

                <div style={{maxWidth: "500px", width: "100%", height: "max-content", overflow: "auto"}}>
                    {
                        searchList.map((nft, index) => {
                            // let image = arrayBufferToBase64(nft.img.data)
                            // console.log('image', image, nft)
                            return <div id='search-card' key={index}>
                                <div className="align-items-center d-flex img position-relative">
                                    <img
                                        src={`data:image/webp;base64,${nft.img.data}`} alt={nft.title}
                                        className="img-fluid"
                                        style={{width: '100%', position: "absolute"}}
                                    />
                                </div>
                                <div className="content">
                                    <Link to={`/Nftfeature/${nft._id}`} onClick={() => setOpenSearch(false)}>
                                        <h4>{nft.title}</h4>
                                    </Link>
                                    <p>{nft.description.substr(0, 80)} {nft.description.length > 80 && '...'} </p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Search