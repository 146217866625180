import React from "react";
import { Link } from "react-router-dom";

function Javacript() {
  return (
    <div>
      {/* <!--Breadcrumb--> */}
      <section className="breadcrumb_flexRow">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              {/* <!--Breadcrumb--> */}
              <section className="breadCrumb_wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/Cryppunks">Kryp.ai</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/Training">Training</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Python
                    </li>
                  </ol>
                </nav>
              </section>

              {/* <!--End Breadcrumb--> */}
            </div>
          </div>
        </div>
      </section>

      {/* <!--End Breadcrumb-->


<!-- Javascript-Section1 --> */}
      <section className="javascript_sec1">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="frame">
                <div
                  className="frame_contentBox scrollBar"
                  style={{
                    marginLeft: "100px",
                    width: "88%",
                    minHeight: "70%",
                    maxHeight: "70%",
                  }}
                >
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mb-4">
                      <div className="headingBg">
                        <label className="subHeading font20">
                          come into our space to see to new
                        </label>
                      </div>
                      <p className="Heading font30 my-3">
                        The Complete Python Course - Beginner to Professional
                      </p>
                      <p className="font14 mt-5">
                        {/* JavaScript (JS) is a lightweight interpreted or JIT-compiled programming language with first-class functions. While it is most well-known as the scripting language for Web pages, many non-browser environments also use it, such as Node.js, Apache CouchDB and Adobe Acrobat. JavaScript is a prototype-based, multi-paradigm, dynamic language, supporting object-oriented, imperative, and declarative (e.g. functional programming) styles. */}
                      </p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="mission_ryt_sec1">
                        <img
                          src="assets/images/Python.jpg"
                          className="img-fluid"
                          alt="planet"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!-- Javascript Row 2 --> */}
                  <div className="mission_sec2 javascript_sec2">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        <p className="font20 text-center">
                          <b>
                            Eligibility Criteria for admissin to this caltech
                            coding bootcamp,candidates:
                          </b>
                        </p>
                        <ul className="javascript_row2_list">
                          <li>
                            {" "}
                            are not required to have prior work experience
                          </li>
                          <li>
                            {" "}
                            at least 18 year and have a hight school diploma or
                            equlvalent
                          </li>
                          <li> may have a non-programming beacground</li>
                        </ul>
                      </div>
                    </div>
                    <div className="abs_sec">
                      <img
                        src="assets/images/planet02.png"
                        className="img-fluid"
                        align="javascript_img2"
                      />
                    </div>
                  </div>
                  {/* <!-- End-Mission Row 2 -->
						<!-- javascript Row 3 --> */}
                  <div className="javascript_sec3 mt-3">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="javascript_sec3_wrap">
                          <p className="font20">
                            3-5-Month Payment Plans <br />
                            Self-Directed
                          </p>
                          <ul>
                            <li>Python-self-directed</li>
                            <li>Help with 2 personal projects</li>
                            <li>Get to know other classmates</li>
                            <li>Help with resume and interview prep</li>
                            <li>Unlimited email and chat Q&A</li>
                            {/* <li>unlimited email and chat Q&A</li>
											<li>6 hours of dedlicated live Q&A</li>
											<li>Access to All future videos and code</li>
											<li>Help with 2 personal projects</li>
											<li>get to know other classNamemates</li>
											<li>help with resume and interview prep</li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="border_padding javascript_sec3_wrap">
                          <p className="font20">
                            3-5-Month Payment Plans <br />
                            Self-Directed
                          </p>
                          <ul>
                            <li>Python-live classes</li>
                            <li>Everything from the self-directed plan</li>
                            <li>Everything from the self-directed plan</li>
                            <li>8 weeks of live classes (2 hrs. Per week)</li>
                            <li>6 hours of dedicated live Q&A</li>
                            <li>Access to all future videos and code</li>
                            {/* <li>Everything From The Self-Directed Plan</li>
											<li>Access To All Future Videos And Code.</li>
											<li>10 Weeks Of Live classNamees (2 Hours Per Week)</li>
											<li>Help With 2 Personal Projects </li>
											<li>6Hours Of Dedicated Live Q&A </li>
											<li>Lifetime Access To Our Video Library </li>
											<li>Lifetime Access To Our Library Of Code </li>
											<li>Access To Cryppunkss Community </li>
											<li>Instructor Support Through Cryppunkss Community </li>
											<li>6 Hours Of Dedicated Live Q&A</li>
											<li>Lifetime Access To Our Video Library</li>
											<li>Lifetime Access To Our Library Of Code</li>
											<li>Access To Cryppunkss Community</li>
											<li>Instructor Support Through Cryppunkss Community</li>
											<li>Access To Weekly Discussion Hour Through Cryppunkss Community</li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End-Javascript Row 3 -->
						<!-- Javascript Row 4 --> */}
                  <div className="javascript_sec4 mt-5">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="headingBg">
                          <label className="subHeading font20">
                            Python Professional 1
                          </label>
                        </div>
                        <p className="Heading font30 my-3">
                          When you will learn
                        </p>
                        <p className="font14 mt-2">
                          This Course Covers The Fundamentals Of Javascript. It
                          Is The Best Way To Learn Javascript In 2022. The
                          Entire Course Was Re-Written In 2021. The Lessons &
                          Challenges Will Guide You Through These Topics Step By
                          Step And Its Project-Based Approach Will Help You
                          Reinforce These Concepts.
                        </p>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="javascript_sec4_li">
                          <ul>
                            <li>Python</li>
                            <li>Computer Programming</li>
                            <li>Algorithmic Thinking</li>
                            <li>Analytical Thinking</li>
                            <li>
                              Design, Develop, Refactor, and Debug Multi-Module
                              applications
                            </li>
                            <li>
                              Best Practices and Python Coding Conventions
                            </li>
                            <li>Solutions Architecture</li>
                            <li>Advanced Object - Oriented Programming</li>
                            <li>GUI Programming</li>
                            <li>Network Programming</li>
                            {/* <li>Reduce</li> */}
                            {/* <li>classNamees</li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="javascript_sec4_li">
                          <ul>
                            <li>
                              Design, develop, debug, execute, and refactor
                              multi-module computer programs written in Python.
                            </li>
                            <li>
                              Analyze and model real-life problems in OOP
                              categories.
                            </li>
                            <li>
                              Use the potential of Python in everyday
                              applications, including IoT and DIY activities.
                            </li>
                            <li>
                              Understand the role of a programmer in the
                              software development process.
                            </li>
                            <li>
                              Understand the differences between procedural and
                              OOP approaches and be able to differentiate the
                              pros and cons of both techniques.
                            </li>
                            <li>
                              Employ the PEP 8 and PEP 257 guidelines, coding
                              conventions, and best practices.
                            </li>
                            <li>
                              Use GUI toolkits, basic blocks, and conventions to
                              design and build simple GUI applications.
                            </li>
                            <li>
                              Design, develop, and improve a simple REST client.
                            </li>
                            <li>
                              Perform database programming operations, handle
                              logging events, and process xml, csv, and
                              configuration files.
                            </li>
                            <li>
                              Create, develop, and improve your own programming
                              portfolio to increase your value in the job
                              market.
                            </li>
                            <li>
                              Continue your professional development at the
                              expert level with the Python Professional 2 course
                              series.
                            </li>
                            {/* <li>DOM Basics</li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="headingBg">
                      <label className="subHeading font20">
                        Python Essentials 2
                      </label>
                    </div>
                    <p className="Heading font25 my-3">
                      Key skills you will learn <br />
                      Sign up for Python Essentials 2, and learn these core
                      skills:
                    </p>
                  </div>
                  <div className="javascript_sec4_li">
                    <ul>
                      <li>Python</li>
                      <li>Computer Programming</li>
                      <li>Algorithmic Thinking</li>
                      <li>Analytical Thinking</li>
                      <li>
                        Design, Develop, Refactor, and Debug Multi-Module
                        applications
                      </li>
                      <li>Best Practices and Python Coding Conventions</li>
                      <li>Solutions Architecture</li>
                      <li>Object - Oriented Programming</li>
                    </ul>

                    <p className="font14 mt-2">
                      This Course Covers The Fundamentals Of Javascript. It Is
                      The Best Way To Learn Javascript In 2022. The Entire
                      Course Was Re-Written In 2021. The Lessons & Challenges
                      Will Guide You Through These Topics Step By Step And Its
                      Project-Based Approach Will Help You Reinforce These
                      Concepts.
                    </p>
                    <ul>
                      <li>
                        Design, develop, debug, execute, and refactor
                        multi-module computer programs written in Python
                      </li>
                      <li>
                        Analyze and model real-life problems in OOP categories.
                      </li>
                      <li>
                        Use the potential of Python in everyday applications,
                        including IoT and DIY activities.
                      </li>
                      <li>
                        Understand the role of a programmer in the software
                        development process.
                      </li>
                      <li>
                        Understand the differences between procedural and OOP
                        approaches and be able to differentiate the pros and
                        cons of both techniques.
                      </li>
                      <li>
                        Create, develop, and improve your own programming
                        portfolio to increase your value in the job market.
                      </li>
                      <li>
                        Continue your professional development at an advanced
                        and more specialized level with the Python Professional
                        1 course series.
                      </li>
                    </ul>
                  </div>

                  {/* <!-- End-Javascript Row 4 --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Javacript;
