import React, { useState } from "react";
import { Link } from "react-router-dom";

function Videos() {
	const [activeTab, setActiveTab] = useState("videos");
	const [categories, setCategories] = useState([
		"Galaxical Space",
		"Bots",
		"Training",
	]);

	// Function to handle tab click
	const handleTabClick = (category) => {
		setActiveTab(category);
	};

	return (
		<div>
			{/* <!-- BreadCrumb & Heading --> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/kryp.ai">Kryp.ai</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											<Link to="/videos">Video</Link>
										</li>
									</ol>
								</nav>
							</section>
							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- BreadCrumb & Heading  --> */}

			{/* Mission Section1 */}
			<section class="mission_sec1">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-12 col-lg-12">
							<div class="frame">
								<div
									class="frame_contentBox scrollBar"
									style={{
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									{/* Training Row 2 */}
									<div class="training_sec2">
										<div class="row">
											<div class="col-12 col-md-12 col-lg-12">
												<div class="tabBox">
													<div class="tab-content" id="pills-tabContent">
														{/* Tab1 */}
														<div
															class={`tab-pane fade ${
																activeTab === "videos" ? "show active" : ""
															}`}
															id="trainingTab3"
															role="tabpanel"
															aria-labelledby="trainingTab3-tab"
														>
															<div class="trainigTab_sec">
																<div class="row text-center">
																	<div class="col-12 col-md-12 col-lg-12">
																		<div class="headingBg">
																			<label class="subHeading font25">
																				come into our space to see to new
																				technology and trenos
																			</label>
																		</div>
																		<p class="Heading font30 mb-3 m-auto">
																			Videos
																		</p>
																	</div>
																</div>
																<div className="videos_content_wrapper pt-3">
																	<div className="row">
																		<div className="col-lg-4">
																			<video
																				className="w-100 h-100"
																				src="./assets/videos/automation-with-ai.mp4" 
                                                                                controls
																			>
                                                                            </video>
																		</div>
																		<div className="col-lg-4">
																			<video
																				className="w-100 h-100"
																				src="./assets/videos/automatizacion.mp4"
                                                                                controls
																			></video>
																		</div>
																		<div className="col-lg-4">
																			<video
																				className="w-100 h-100"
																				src="./assets/videos/cables-en-espanol.mp4"
                                                                                controls
																			></video>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														{/* Tab2 */}
														{/* Tab3 */}
														{/* <div
															class={`tab-pane fade ${
																activeTab === "Training101" ? "show active" : ""
															}`}
															id="trainingTab3"
															role="tabpanel"
															aria-labelledby="trainingTab3-tab"
														>
															<div class="trainigTab_sec">
																<div class="row text-center">
																	<div class="col-12 col-md-12 col-lg-12">
																		<div class="headingBg">
																			<label class="subHeading font25">
																				come into our space to see to new
																			</label>
																		</div>
																		<p class="Heading font30 mb-3 m-auto">
																			Training
																		</p>
																	</div>
																</div>
																
															</div>
														</div> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* End-Training Row 2 */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End-Mission-Section1 */}
		</div>
	);
}

export default Videos;
