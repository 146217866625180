import axios from 'axios';
import { BASE_URL } from '..';


export const MembersApi = {
    members: async () => {
        const url = `${BASE_URL}/members`
        try {
            const response = await axios.post(url);
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.', error)
            return error.response.data
        }
    },

    deleteMember: async (data) => {
        const url = `${BASE_URL}/member/delete`
        try {
            const response = await axios.post(url, data);
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.', error)
            return error.response.data
        }
    },

    countMembers: async () => {
        const url = `${BASE_URL}/counts`
        try {
            const response = await axios.get(url);
            return response.data
        } catch (error) {
            // Handle network errors
            console.log('Error', 'An error occurred. Please try again later.', error)
            return error.response.data
        }
    }
}