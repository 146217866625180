import React from "react";
import { Link } from "react-router-dom";
import CustomModal2 from "../Components/CustomModal/CustomeModal2";

function Css() {
	const javascriptData = [
		{
			id: 1,
			threeMonth: [
				{
					id: 1,
					title: "Adding Your Own Style",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/Adding-your-own-style/Css-cssframeworks%20-intro-to-css.pdf",
						},
					],
				},
				{
					id: 2,
					title: "Colors, Fonts & sizes in CSS",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/Colors-fonts-sizes-in-css/Slides-(css-1-colors-fonts-and-sizes-in-css).pdf",
						},
					],
				},
				{
					id: 3,
					title: "Deep Dive",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/Deep-dive/Advanced-selector-slides-(css-1-deep-dive).pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/Deep-dive/Order-priority-slides-(css-1-deep-dive).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 4,
					title: "Introduction",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/Introduction/Slides-(css-1-introduction).pdf",
						},
					],
				},
				{
					id: 5,
					title: "WorkBook 1, Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/WorkBook1-quiz/Module1-workbook.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-1/WorkBook1-quiz/The-intro-to-css-quiz-part-of-the-css-1-quizzes.pdf",
						},
					],
				},
			],
			day: "DAY 1",
		},
		{
			id: 2,
			threeMonth: [
				{
					id: 6,
					title: "CSS Newspaper Activity",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-2/Css-newspaper-activity/Css-cssframeworks-newspaperactivity.pdf",
						},
					],
				},
				{
					id: 7,
					title: "Developer Tools",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-2/Developer-tools/Slides-(css-2-developer-tools).pdf",
						},
					],
				},
				{
					id: 8,
					title: "Styling Practice",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-2/Styling-practice/Css-cssframeworks-simplestyling.pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 9,
					title: "The Box Model",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-2/The-box-model/Slides-(css-2-the-box-model).pdf",
						},
					],
				},
				{
					id: 10,
					title: "WorkBook2, Quiz2",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-2/WorkBook2-quiz2/Module2-workbook.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-2/WorkBook2-quiz2/This-quiz-focusesonbasic-styling-concepts-in-css-(cascading-style-sheets).pdf",
						},
					],
				},
			],
			day: "DAY 2",
		},
		{
			id: 3,
			threeMonth: [
				{
					id: 11,
					title: "CSS Positioning",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/CSS-positioning/Css-cssframeworks-css-positioning.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/CSS-positioning/Slides-(css-3-css-positioning).pdf",
						},
					],
				},
				{
					id: 12,
					title: "Introduction To Animations",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/Introduction-to-animations/Sd03-css-cssframeworks-animation-and-transforms.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/Introduction-to-animations/Slides-(css3-introduction-to-animations).pdf",
						},
					],
				},
				{
					id: 13,
					title: "Practice",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/Practice/Sd03-css-cssframeworks-animation-and-transforms.pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 14,
					title: "The Hacker Times Part II",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/The-hacker-times-partII/Css-cssframeworks-css-hacker-times.pdf",
						},
					],
				},
				{
					id: 15,
					title: "WorkBook 3, Quiz3",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/WorkBook3-quiz3/Module3-workbook.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-3/WorkBook3-quiz3/Positioning-animations-quiz-(css-3-quizzes).pdf",
						},
					],
				},
			],
			day: "DAY 3",
		},
		{
			id: 4,
			threeMonth: [
				{
					id: 16,
					title: "From Mockups To Code",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/From-mockups-to-code/Sd03-css-cssframeworks-responsivelayout-flexbox.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/From-mockups-to-code/Slides-(css4-from-mockups-to-code).pdf",
						},
					],
				},
				{
					id: 17,
					title: "Introduction To Flexbox",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/Introduction-to-flexbox/Slides-(css4-introduction-to-flexbox).pdf",
						},
					],
				},
				{
					id: 18,
					title: "Quiz4",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/Quiz4/4.%20Responsive-layout-flexbox-%20quiz-(css-4-quizzes).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 19,
					title: "Responsive Boxes",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/Responsive-boxes/Sd03-css-cssframeworks-responsive-layout-flexbox.pdf",
						},
					],
				},
				{
					id: 20,
					title: "Responsive Layout",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/Responsive-layout/Sd03-css-cssframeworks-responsive-layout-flexbox.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-4/Responsive-layout/Slides-(css4-responsive-layout).pdf",
						},
					],
				},
			],
			day: "DAY 4",
		},
		{
			id: 5,
			threeMonth: [
				{
					id: 21,
					title: "Copy Cat Part II",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-5/Copy-cat-partII/Sd03-css-cssframeworks-copycat.pdf",
						},
					],
				},
				{
					id: 22,
					title: "Introduction To CSS Frameworks",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-5/Introduction-to-css-frameworks/Sd03-css-cssframeworks-cssframeworks.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-5/Introduction-to-css-frameworks/Slides-(css5-introduction-to-css-frameworks).pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 23,
					title: "Quiz5",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-5/Quiz5/cssframeworks-recreation-quiz-(css5-quizzes).pdf",
						},
					],
				},
				{
					id: 24,
					title: "Recreate Oscar Website Design",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/Css/Day-5/Recreate-oscar-website-design/Sd03-css-css-frameworks-recreate-oscar.pdf",
						},
					],
				},
			],
			day: "DAY 5",
		},
	];

	return (
		<div>
			{/* <!--Breadcrumb--> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Kryp.ai">Kryp.ai</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Training">Training</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Css
										</li>
									</ol>
								</nav>
							</section>

							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>

			{/* <!--End Breadcrumb-->


<!-- Javascript-Section1 --> */}
			<section className="javascript_sec1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div
									className="frame_contentBox scrollBar"
									style={{
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									<div className="row align-items-center justify-content-center">
										<div className="col-12 col-md-8 col-lg-12 mb-4">
											<div className="headingBg mb-3 text-center">
												<label className="subHeading font20">
													come into our space to see to new
												</label>
											</div>
											<p className="Heading text-center font30 my-3">
												Css courses
											</p>
											<div className="col-12 col-md-6 col-lg-6 m-auto">
												<div className="mission_ryt_sec1">
													<img
														src="assets/images/html-css.jpg"
														className="img-fluid"
														alt="planet"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="tabs-upper pt-4">
										<div className="row">
											<div className="col-lg-12">
												<div className="courses-tabs-wrapper">
													<ul class="nav nav-tabs border-0" role="tablist">
														{javascriptData?.map((items, index) => (
															<li class="nav-item" key={index}>
																<a
																	class={`nav-link ${
																		items?.id === 1 ? "active" : ""
																	}`}
																	data-bs-toggle="tab"
																	href={`#${items?.id}`}
																>
																	{items?.day}
																</a>
															</li>
														))}
													</ul>
													<div class="tab-content">
														{javascriptData?.map((item, index) => (
															<div
																id={item?.id}
																key={index}
																class={`container tab-pane ${
																	item?.id === 1 ? "active" : ""
																}`}
															>
																<div class="javascript_sec3 mt-3">
																	<div class="row">
																		<div class="col-12 col-md-6 col-lg-4">
																			<div class="javascript_sec3_wrap">
																				<p class="font18">3-Month plan</p>
																				<ul className="p-0">
																					{item?.threeMonth?.map((data, i) => {
																						return (
																							<li key={i}>
																								<CustomModal2
																									id={data?.id}
																									title={data?.title}
																									iframeSrc={data?.iframes}
																								/>
																							</li>
																						);
																					})}
																				</ul>
																			</div>
																		</div>
																		<div class="col-12 col-md-6 col-lg-4">
																			<div class="javascript_sec3_wrap">
																				<p class="font18">5-Month plan</p>
																				<ul className="p-0">
																					{item?.fiveMonth?.map((datas, i) => {
																						return (
																							<li key={i}>
																								<CustomModal2
																									id={datas?.id}
																									title={datas?.title}
																									iframeSrc={datas?.iframes}
																								/>
																							</li>
																						);
																					})}
																				</ul>
																			</div>
																		</div>
																		<div class="col-12 col-md-6 col-lg-4"></div>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- Javascript Row 2 --> */}
									<div className="mission_sec2 javascript_sec2">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<p className="font20 text-center">
													<b>
														Eligibility Criteria for admissin to this caltech
														coding bootcamp,candidates:
													</b>
												</p>
												<ul className="javascript_row2_list">
													<li>
														{" "}
														are not required to have prior work experience
													</li>
													<li>
														{" "}
														at least 18 year and have a hight school diploma or
														equlvalent
													</li>
													<li> may have a non-programming beacground</li>
												</ul>
											</div>
										</div>
										<div className="abs_sec">
											<img
												src="assets/images/planet02.png"
												className="img-fluid"
												align="javascript_img2"
											/>
										</div>
									</div>
									{/* <!-- End-Mission Row 2 -->
						<!-- javascript Row 3 --> */}
									<div className="javascript_sec3 mt-3">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec3_wrap">
													<p className="font20">
														3-Month plan <br />
														Initial down payment
													</p>
													<ul>
														<li>1St $</li>
														<li>2Nd $$</li>
														<li>3Rd $$$</li>
														<li>Everything from the self-directed plan</li>
														<li>
															8 weeks of live classNameess (2 Hrs. per week)
														</li>
														<li>unlimited email and chat Q&A</li>
														<li>6 hours of dedlicated live Q&A</li>
														<li>Access to All future videos and code</li>
														<li>Help with 2 personal projects</li>
														<li>get to know other classNamemates</li>
														<li>help with resume and interview prep</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="border_padding javascript_sec3_wrap">
													<p className="font20">
														3-Month plan <br />
														Initial down payment
													</p>
													<ul>
														<li>1St $</li>
														<li>2Nd $$</li>
														<li>3Rd $$$</li>
														<li>4Th $$$$</li>
														<li>5Th $$$$$</li>
														<li>JAVASCRIPT-Live classNamees</li>
														<li>Everything From The Self-Directed Plan</li>
														<li>Access To All Future Videos And Code.</li>
														<li>
															10 Weeks Of Live classNamees (2 Hours Per Week)
														</li>
														<li>Help With 2 Personal Projects </li>
														<li>6Hours Of Dedicated Live Q&A </li>
														<li>Lifetime Access To Our Video Library </li>
														<li>Lifetime Access To Our Library Of Code </li>
														<li>Access To Cryppunkss Community </li>
														<li>
															Instructor Support Through Cryppunkss Community{" "}
														</li>
														<li>6 Hours Of Dedicated Live Q&A</li>
														<li>Lifetime Access To Our Video Library</li>
														<li>Lifetime Access To Our Library Of Code</li>
														<li>Access To Cryppunkss Community</li>
														<li>
															Instructor Support Through Cryppunkss Community
														</li>
														<li>
															Access To Weekly Discussion Hour Through
															Cryppunkss Community
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Javascript Row 3 -->
						<!-- Javascript Row 4 --> */}
									<div className="javascript_sec4 mt-5">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="headingBg">
													<label className="subHeading font20">
														come into our space to see to new
													</label>
												</div>
												<p className="Heading font30 my-3">
													When you will learn
												</p>
												<p className="font14 mt-2">
													This Course Covers The Fundamentals Of Javascript. It
													Is The Best Way To Learn Javascript In 2022. The
													Entire Course Was Re-Written In 2021. The Lessons &
													Challenges Will Guide You Through These Topics Step By
													Step And Its Project-Based Approach Will Help You
													Reinforce These Concepts.
												</p>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec4_li">
													<ul>
														<li>Arrays. -Advanced DOM</li>
														<li>Functons -Events</li>
														<li>Arrow Functions. -Forms</li>
														<li>Advanced Arrays - Package Managers</li>
														<li>Destructuring. - Module Bundlers</li>
														<li>Optional Chaining. - Modules</li>
														<li>Nullish Coalescing. - Dynamic Imports</li>
														<li>Objects - Ecmascrips</li>
														<li>Arrays Of Objects</li>
														<li>Advanced Control Flow</li>
														<li>Reduce</li>
														<li>classNamees</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec4_li">
													<ul>
														<li>Callbacks</li>
														<li>Asynchronous Logic</li>
														<li>Arrow Functions</li>
														<li>Prototypical Inheritance</li>
														<li>JSON</li>
														<li>Promises</li>
														<li>Fetch</li>
														<li>Work With Real Apis</li>
														<li>Lexical Scope</li>
														<li>Async/Await</li>
														<li>DOM Selection</li>
														<li>DOM Basics</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Javascript Row 4 --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Css;
