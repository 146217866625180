import React, { useEffect, useState } from 'react'
import './AdminLayout.css';
import { Link, useNavigate } from 'react-router-dom';

const AdminLayout = ({children}) => {

    const [loading , setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        const _def = async () => {
            const user = await localStorage.getItem('user');
            const isloggedIn = await localStorage.getItem('isloggedin', true);
            if(!isloggedIn)
            {
                navigate('/login')
                return false
            }
            if(isloggedIn && user)
            {
                let _user = await JSON.parse(user)
                if(_user.role !== 'admin')
                {
                    navigate('/')
                    return false
                }
            }
            setLoading(false)
        }
        _def()
    }, [])

    return (
        <div className="container">
            <div className="admin-menu">
                <ul>
                    <li>
                        <Link to={'/AdminDashboard'}>Upload NFT</Link>
                    </li>
                    <li>
                        <Link to={'/Members'}>Members</Link>
                    </li>
                </ul>
            </div>
            {
                !loading && children
            }
        </div>
    )
}

export default AdminLayout