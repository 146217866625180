import React, { useEffect, useState } from 'react'
import AdminLayout from '../../Components/AdminLayout'
import { MembersApi } from '../../services/admin/Members'
import { BASE_URL } from '../../services'

const Members = () => {

    const [users, setUsers] = useState([])

    const deleteFunc = async (id) => {
        let res = await MembersApi.deleteMember({_id: id})
        if(res.status === 200)
        {
            setUsers(users.filter(user => user._id !== id))
        }
    }

    useEffect(() => {
        const _def = async () => {
            const res = await MembersApi.members()
            setUsers(res.users)
        }
        _def()
    }, [])

    return (
        <AdminLayout>
            <table className=" ">
                <thead>
                    <tr>
                        <th>Sr #</th>
                        <th>Image</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Registration Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{background: "#f7f7f7"}}>
                    {
                        users.map((user, index) => {
                            return <tr key={index}>
                                <td>#{index+1}</td>
                                <td>
                                    {
                                        user.picture ? (
                                            <img 
                                            style={{borderRadius: '100%', width: "50px"}} 
                                            src={`${BASE_URL}/${user.picture}`} />
                                        ) : (
                                            <span className="text-secondary small">N/A</span>
                                        )
                                    }
                                </td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.registration_date ? user.registration_date.split('T')[0] : ''}</td>
                                <td>
                                    <button type="button" onClick={() => deleteFunc(user._id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{width: "25px", height: "25px"}}>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>

                                    </button>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </AdminLayout>
    )
}

export default Members