import React from "react";
import { Link } from "react-router-dom";
import CustomModal2 from "../Components/CustomModal/CustomeModal2";

function Uiux() {
	const javascriptData = [
		{
			id: 1,
			threeMonth: [
				{
					id: 1,
					title: "Common Design Styles",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-1/Common-design-styles/Activity-evaluating-successful-design-part2.pdf",
						},
					],
				},
				{
					id: 2,
					title: "Coursebook",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-1/Coursebook/Module1-course-book.pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 3,
					title: "Introduction To Design",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-1/Introduction-to-design/Activity-what-design-means-to-you.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-1/Introduction-to-design/Slides-(uxui1-introduction-to-design).pdf",
						},
					],
				},
				{
					id: 4,
					title: "What is Successful Design",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-1/What-is-successful-design/Activity-evaluating-successful-design-part1.pdf",
						},
					],
				},
			],
			day: "DAY 1",
		},
		{
			id: 2,
			threeMonth: [
				{
					id: 5,
					title: "Basic of color Theory",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Basic-of-color-theory/Activity-developing-an-eye-for-color.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Basic-of-color-theory/Slides-(uxui2-basics-of-color-theory).pdf",
						},
					],
				},
				{
					id: 6,
					title: "Basics of Layout and composition",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Basics-of-layout-and-composition/Activity-developing-an-eye-for-design.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Basics-of-layout-and-composition/Slides-(uxui2-basics-of-layout-and-composition).pdf",
						},
					],
				},
			],
			fiveMonth: [
        {
					id: 7,
					title: "Basics of Typography",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Basics-of-typography/Activity-developing-an-eye-for-type.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Basics-of-typography/Slides-(uxui2-basics-of-typography).pdf",
						},
					],
				},
				{
					id: 8,
					title: "Coursebook_Worbook",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Coursebook-worbook/Module02-workbook.pdf",
						},
            {
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-2/Coursebook-worbook/Module2-coursebook.pdf",
						},
					],
				},
			],
			day: "DAY 2",
		},
		{
			id: 3,
			threeMonth: [
				{
					id: 9,
					title: "Basics of Design Research",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Basics-of-design-research/Activity-competitive-analysis.pdf",
						},
            {
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Basics-of-design-research/Slides-(uxui3-basics-of-design-research).pdf",
						},
					],
				},
				{
					id: 10,
					title: "CourseBook3,Workbook,Quiz",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Coursebook3-workbook-quiz/Module02-workbook.pdf",
						},
						{
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Coursebook3-workbook-quiz/Module3-course-book.pdf",
						},
            {
							id: 3,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Coursebook3-workbook-quiz/Quiz1-is-the-final-quiz-for-the-uxui-course.pdf",
						},
					],
				},
			],
			fiveMonth: [
				{
					id: 11,
					title: "Designing with Canva",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Designing-with-canva/Activity-creating-social-banners.pdf",
						},
            {
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/Designing-with-canva/Slides-(uxui3-designing-with-canva).pdf",
						},
					],
				},
        {
					id: 12,
					title: "What Is User-Centered Desgin",
					iframes: [
						{
							id: 1,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/What-is-user-centered-desgin/Activity-being-user-centered.pdf",
						},
            {
							id: 2,
							src: "https://flowpaper.com/flipbook/https://developer-ourbase-camp.com/pdfs/UXUI/Day-3/What-is-user-centered-desgin/Slides-(uxui3-what-is-user-centered-design-).pdf",
						},
					],
				},
			],
			day: "DAY 3",
		},
	];

	return (
		<>
			{/* <!--Breadcrumb--> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Kryp.ai">Kryp.ai</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/Training">Training</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Uiux
										</li>
									</ol>
								</nav>
							</section>

							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>

			{/* <!--End Breadcrumb-->


<!-- Javascript-Section1 --> */}
			<section className="javascript_sec1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div
									className="frame_contentBox scrollBar"
									style={{
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									<div className="row">
										<div className="col-lg-6">
											<div className="headingBg mb-3 ">
												<label className="subHeading font20">
													come into our space to see to new
												</label>
											</div>
											<p className="Heading font30 my-3">
												UIUX courses
											</p>
											<div className="courses-tabs-wrapper">
												<ul class="nav nav-tabs border-0" role="tablist">
													{javascriptData?.map((items, index) => (
														<li class="nav-item" key={index}>
															<a
																class={`nav-link ${
																	items?.id === 1 ? "active" : ""
																}`}
																data-bs-toggle="tab"
																href={`#${items?.id}`}
															>
																{items?.day}
															</a>
														</li>
													))}
												</ul>
												<div class="tab-content">
													{javascriptData?.map((item, index) => (
														<div
															id={item?.id}
															key={index}
															class={`container tab-pane ${
																item?.id === 1 ? "active" : ""
															}`}
														>
															<div class="javascript_sec3 mt-3">
																<div class="row">
																	<div class="col-12 col-md-6 col-lg-6">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">3-Month plan</p>
																			<ul className="p-0">
																				{item?.threeMonth?.map((data, i) => {
																					return (
																						<li key={i}>
																							<CustomModal2
																								id={data?.id}
																								title={data?.title}
																								iframeSrc={data?.iframes}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																	<div class="col-12 col-md-6 col-lg-6">
																		<div class="javascript_sec3_wrap">
																			<p class="font18">5-Month plan</p>
																			<ul className="p-0">
																				{item?.fiveMonth?.map((datas, i) => {
																					return (
																						<li key={i}>
																							<CustomModal2
																								id={datas?.id}
																								title={datas?.title}
																								iframeSrc={datas?.iframes}
																							/>
																						</li>
																					);
																				})}
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
										<div className="col-12 col-md-8 col-lg-6 mb-4">
											<div className="mission_ryt_sec1">
												<img
													src="assets/images/ui-main.jpg"
													className="img-fluid"
													alt="planet"
												/>
											</div>
										</div>
									</div>
									{/* <!-- Javascript Row 2 --> */}
									<div className="mission_sec2 javascript_sec2">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<p className="font20 text-center">
													<b>
														Eligibility Criteria for admissin to this caltech
														coding bootcamp,candidates:
													</b>
												</p>
												<ul className="javascript_row2_list">
													<li>
														{" "}
														are not required to have prior work experience
													</li>
													<li>
														{" "}
														at least 18 year and have a hight school diploma or
														equlvalent
													</li>
													<li> may have a non-programming beacground</li>
												</ul>
											</div>
										</div>
										<div className="abs_sec">
											<img
												src="assets/images/planet02.png"
												className="img-fluid"
												align="javascript_img2"
											/>
										</div>
									</div>
									{/* <!-- End-Mission Row 2 -->
						<!-- javascript Row 3 --> */}
									<div className="javascript_sec3 mt-3">
										<div className="row">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec3_wrap">
													<p className="font20">
														3-Month plan <br />
														Initial down payment
													</p>
													<ul>
														<li>1St $</li>
														<li>2Nd $$</li>
														<li>3Rd $$$</li>
														<li>Everything from the self-directed plan</li>
														<li>
															8 weeks of live classNameess (2 Hrs. per week)
														</li>
														<li>unlimited email and chat Q&A</li>
														<li>6 hours of dedlicated live Q&A</li>
														<li>Access to All future videos and code</li>
														<li>Help with 2 personal projects</li>
														<li>get to know other classNamemates</li>
														<li>help with resume and interview prep</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="border_padding javascript_sec3_wrap">
													<p className="font20">
														3-Month plan <br />
														Initial down payment
													</p>
													<ul>
														<li>1St $</li>
														<li>2Nd $$</li>
														<li>3Rd $$$</li>
														<li>4Th $$$$</li>
														<li>5Th $$$$$</li>
														<li>JAVASCRIPT-Live classNamees</li>
														<li>Everything From The Self-Directed Plan</li>
														<li>Access To All Future Videos And Code.</li>
														<li>
															10 Weeks Of Live classNamees (2 Hours Per Week)
														</li>
														<li>Help With 2 Personal Projects </li>
														<li>6Hours Of Dedicated Live Q&A </li>
														<li>Lifetime Access To Our Video Library </li>
														<li>Lifetime Access To Our Library Of Code </li>
														<li>Access To Cryppunkss Community </li>
														<li>
															Instructor Support Through Cryppunkss Community{" "}
														</li>
														<li>6 Hours Of Dedicated Live Q&A</li>
														<li>Lifetime Access To Our Video Library</li>
														<li>Lifetime Access To Our Library Of Code</li>
														<li>Access To Cryppunkss Community</li>
														<li>
															Instructor Support Through Cryppunkss Community
														</li>
														<li>
															Access To Weekly Discussion Hour Through
															Cryppunkss Community
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Javascript Row 3 -->
						<!-- Javascript Row 4 --> */}
									<div className="javascript_sec4 mt-5">
										<div className="row">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="headingBg">
													<label className="subHeading font20">
														come into our space to see to new
													</label>
												</div>
												<p className="Heading font30 my-3">
													When you will learn
												</p>
												<p className="font14 mt-2">
													This Course Covers The Fundamentals Of Javascript. It
													Is The Best Way To Learn Javascript In 2022. The
													Entire Course Was Re-Written In 2021. The Lessons &
													Challenges Will Guide You Through These Topics Step By
													Step And Its Project-Based Approach Will Help You
													Reinforce These Concepts.
												</p>
											</div>
										</div>
										<div className="row mt-4">
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec4_li">
													<ul>
														<li>Arrays. -Advanced DOM</li>
														<li>Functons -Events</li>
														<li>Arrow Functions. -Forms</li>
														<li>Advanced Arrays - Package Managers</li>
														<li>Destructuring. - Module Bundlers</li>
														<li>Optional Chaining. - Modules</li>
														<li>Nullish Coalescing. - Dynamic Imports</li>
														<li>Objects - Ecmascrips</li>
														<li>Arrays Of Objects</li>
														<li>Advanced Control Flow</li>
														<li>Reduce</li>
														<li>classNamees</li>
													</ul>
												</div>
											</div>
											<div className="col-12 col-md-6 col-lg-6">
												<div className="javascript_sec4_li">
													<ul>
														<li>Callbacks</li>
														<li>Asynchronous Logic</li>
														<li>Arrow Functions</li>
														<li>Prototypical Inheritance</li>
														<li>JSON</li>
														<li>Promises</li>
														<li>Fetch</li>
														<li>Work With Real Apis</li>
														<li>Lexical Scope</li>
														<li>Async/Await</li>
														<li>DOM Selection</li>
														<li>DOM Basics</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End-Javascript Row 4 --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Uiux;
