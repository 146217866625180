import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {setData} from '../utils/storage'
import "./Login.css"
// src/components/Login.js
// import "../dashboard.css";
import { AuthApi } from '../services/AuthService';

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = {
      email: username,
      password: password

    }

    const res = await AuthApi.singIn(data)
    if (res.status === 200) {
      localStorage.setItem('token', res.token);
      localStorage.setItem('user', JSON.stringify(res.user));
      localStorage.setItem('isloggedin', true);
      AuthApi.setLoggedIn({_id: res.user._id, is_logged_in: true})
      if(res.user.role == 'admin')
      {
        window.location.href = '/AdminDashboard'
      }
      else
      {
        window.location.href = "/";
      }
    } else {
      setError(res.message);
    }
  };

    return (
        <div>
        {/* <!--Breadcrumb--> */}
        <section className="breadcrumb_flexRow">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-4">
                        {/* <!--Breadcrumb--> */}
                        <section className="breadCrumb_wrapper">
                            <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">LOGIN</li>
                            </ol>
                            </nav>	
                        </section>
                        {/* <!--End Breadcrumb--> */}
                    </div>
                </div>
            </div>
        </section>

        {/* <!--End Breadcrumb--> */}

        {/* <!-- SpaceIn-Misson-Section --> */}
        <section className="SpaceIn_Misson_sec" id="memberShip_sec">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                        <div className="frame">
                            <div className="frame_contentBox">
                                <div className="row align-items-baseline">
                                    <div className="col-12 col-md-6 col-lg-6 offset-md-4 offset-lg-4">
                                    <p className="Heading font30">
                                        Login 
                                    </p>
                                    </div>
                                    <div className="col-12 col-md-2 col-lg-2">
                                    <img src="assets/images/hourglass.png" alt="spaced-in-img1" className="img-fluid vector_img" />
                                    </div>	
                                </div>
                                
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="form_wrapper px-5">
                                    <div className="row">
                                        <div className="col-12">
                                        {
                                            error && (
                                            <div className="text-danger">
                                                {error}
                                            </div>
                                            )
                                        }
                                        </div>
                                        <div className="col-12">
                                        <div className="fieldWrap mb-3">
                                            <label for="fName">Username</label>
                                            <input 
                                            type="email"
                                            placeholder="Username"
                                            id='firstName'
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            className="form-control" />
                                        </div>
                                        </div>
                                        <div className="col-12">
                                        <div className="fieldWrap mb-3">
                                            <label for="password">Password</label>
                                            <input type="password" 
                                            placeholder="Password"
                                            id='password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control" />
                                        </div>

                                        </div>
                                    </div>
                                    <div className="btnWrapper mt-4">
                                        <button type="submit" className="customBtn">
                                        <img src="assets/images/btnBg.png" className="img-fluid" />
                                        <span>
                                            Submit <i className="fa-solid fa-arrow-right"></i>
                                        </span> 
                                        </button>
                                    </div>
                                    </div>
                                </form>
                            </div>	
                        </div>
                    </div>
                </div>
            </div>
        </section>
            {/* <div className="login-container">
            <div className="login-form">
                <h1>Login</h1>
                <form onSubmit={handleSubmit}>
                {error && <p className="error text-danger">{error}</p>}
                <input
                    type="email"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button className="button2" type="submit">Login</button>
                <p className="text-white">
                    <p style={{fontWeight: "500", fontSize: "15px", textAlign: "center"}}><Link to="/RegisterForm" style={{textDecoration: "underline !important", fontSize: "15px"}}>Create Account?</Link></p>
                </p>
                </form>
                </div>
            </div> */}
        </div>
    );
};

export default Login;
