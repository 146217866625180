import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
	return (
		<div>
			{/* <!--Breadcrumb--> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Privacy Policy
										</li>
									</ol>
								</nav>
							</section>

							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>

			{/* <!--End Breadcrumb--> */}

			{/* <!-- SpaceIn-Misson-Section --> */}
			<section className="SpaceIn_Misson_sec p_t_sec">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div className="frame_contentBox scrollBar scroll_adjust">
									<div className="container">
										<div className="row ">
											<div className="col-12 col-md-12 col-lg-12">
												<div className="privacy_top text-center">
													<div className="headingBg mb-3">
														<label className="subHeading font25">
															come into our space to see to new
														</label>
													</div>
													<p className="Heading font30 m-auto">
													Privacy Policy for Kryp.ai
													</p>
												</div>
												<div className="peraBox mt-4">
													{/* <p className="font14">
											Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Pellentesque Habitant Morbi Tristique Senectus Et Netus Et. Tellus At Urna Condimentum Mattis. Ultricies Integer Quis Auctor Elit Sed Vulputate Mi Sit Amet. Sagittis Nisl Rhoncus Mattis Rhoncus Urna Neque Viverra. Convallis Convallis Tellus Id Interdum Velit Laoreet Id Donec. Sit Amet Facilisis Magna Etiam Tempor Orci Eu. Scelerisque Felis Imperdiet Proin Fermentum Leo Vel Orci. Sed Elementum Tempus Egestas Sed. Velit Scelerisque In Dictum Non Consectetur A Erat Nam At. Aliquam Etiam Erat Velit Scelerisque In Dictum. Odio Eu Feugiat Pretium Nibh Ipsum Consequat Nisl Vel Pretium. Eu Sem Integer Vitae Justo Eget Magna. Montes Nascetur Ridiculus Mus Mauris Vitae Ultricies Leo. Integer Malesuada Nunc Vel Risus Commodo Viverra Maecenas. Ut Porttitor Leo A Diam Sollicitudin. Tincidunt Praesent Semper Feugiat Nibh Sed. Commodo Odio Aenean Sed Adipiscing Diam Donec Adipiscing Tristique. Quis Enim Lobortis Scelerisque Fermentum.
										</p>
										<p className="font14 mt-4">
											Non Odio Euismod Lacinia At Quis Risus. Rhoncus Est Pellentesque Elit Ullamcorper Dignissim Cras Tincidunt. Lectus Mauris Ultrices Eros In Cursus Turpis. Leo In Vitae Turpis Massa Sed Elementum. Aliquet Bibendum Enim Facilisis Gravida. Eget Velit Aliquet Sagittis Id. Posuere Sollicitudin Aliquam Ultrices Sagittis Orci A Scelerisque Purus. Phasellus Vestibulum Lorem Sed Risus Ultricies Tristique Nulla Aliquet Enim. Mauris Sit Amet Massa Vitae Tortor Condimentum Lacinia Quis. Pretium Nibh Ipsum Consequat Nisl Vel Pretium Lectus Quam. Purus In Mollis Nunc Sed. Viverra Suspendisse Potenti Nullam Ac Tortor Vitae Purus Faucibus. Vel Orci Porta Non Pulvinar Neque Laoreet Suspendisse Interdum. Sodales Ut Etiam Sit Amet Nisl Purus In Mollis Nunc. In Nisl Nisi Scelerisque Eu Ultrices Vitae Auctor Eu.
										</p> */}
													<div className="privacy_wrapper">
														<ol className="privacy_ol list-item">
															<li className="privacy_li">
																Introduction
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																		● Welcome to Kryp.ai.
																	</li>
																	<li className="privacy_ul_li">
																		● This Privacy Policy explains how we
																		collect, use, disclose, and safeguard your
																		information when you visit our website
																		[insert website link] and use our services.
																	</li>
																</ul>
																<li className="privacy_li">
																	Information Collection and Use
																</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																		● Types of Data Collected:
																	</li>
																	<li className="privacy_ul_li">
																		● Personal Data: While using our Service, we
																		may ask you to provide us with certain
																		personally identifiable information.
																	</li>
																	<li className="privacy_ul_li">
																		● Usage Data: We may also collect
																		information on how the Service is accessed
																		and used.
																	</li>
																	<li className="privacy_ul_li">
																		● Use of Data: Explain how Kryp.ai will use
																		the collected data.
																	</li>
																</ul>
																<li className="privacy_li">
																	Data Sharing and Disclosure
																</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																		● Clarify the circumstances under which
																		Kryp.ai may share or disclose user data,
																		including legal requirements, business
																		transfers, and to service providers.
																	</li>
																</ul>
																<li className="privacy_li">Data Security</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																		● Describe the measures Kryp.ai takes to
																		protect user data from unauthorized access,
																		disclosure, or destruction.
																	</li>
																</ul>
																<li className="privacy_li">Your Privacy Rights</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																	●	Explain the rights users have regarding their data, such as access, correction, and deletion rights.
																	</li>
																</ul>
																<li className="privacy_li">International Data Transfers</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																	●	If applicable, describe how Kryp.ai transfers data internationally and the protections in place.
																	</li>
																</ul>
																<li className="privacy_li">Children's Privacy</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																	●	Specify the approach of Kryp.ai towards children's data, especially if your service does not address anyone under the age of 13.
																	</li>
																</ul>
																<li className="privacy_li">Changes to This Privacy Policy</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																	●	State that Kryp.ai may update its Privacy Policy and how users will be informed of any changes.
																	</li>
																</ul>
																<li className="privacy_li">Contact Us</li>
																<ul className="privacy_ul">
																	<li className="privacy_ul_li">
																	●	Provide information on how users can contact Kryp.ai regarding privacy concerns.
																	</li>
																</ul>
															</li>
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End-SpaceIn-Section1 --> */}
		</div>
	);
}

export default Privacy;
