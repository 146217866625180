import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterPopup from "../Components/RegisterPopup";
import { NftImagesApi } from "../services/admin/NftImages";
import { MembersApi } from "../services/admin/Members";
import io from "socket.io-client";
import { AuthApi } from "../services/AuthService";

function Home() {
	const [mints, setMints] = useState(0);
	const [members, setMembers] = useState(0);
	const [loggedInMembers, setLoggedInMembers] = useState(0);
	const navigate = useNavigate();

	const redirectToSpacedIn = async () => {
		let isloggedin = await localStorage.getItem("isloggedin");
		if (isloggedin) {
			navigate("/Spacedin");
		} else {
			OpenRegisterPopup();
		}
	};

	const OpenRegisterPopup = () => {
		let registerPopupContainer = document.querySelector(
			"#register-popup-container",
		);
		if (registerPopupContainer) {
			registerPopupContainer.classList.add("d-flex");
			registerPopupContainer.classList.remove("d-none");
			setTimeout(() => {
				registerPopupContainer.style.opacity = "1";
			}, 300);
		}
	};

	const closeRegisterPopup = () => {
		let registerPopupContainer = document.querySelector(
			"#register-popup-container",
		);
		if (registerPopupContainer) {
			registerPopupContainer.style.opacity = "0";
			setTimeout(() => {
				registerPopupContainer.classList.remove("d-flex");
				registerPopupContainer.classList.add("d-none");
			}, 300);
		}
	};

	useEffect(() => {
		const _def = async () => {
			const res = await NftImagesApi.Counts();
			setMints(res.counts);
		};
		_def();

		const _countMemberFunc = async () => {
			const res = await MembersApi.countMembers();
			setMembers(res.counts);
		};
		_countMemberFunc();

		const _countLoggedinMemberFunc = async () => {
			const res = await AuthApi.loggedInUsers();
			setLoggedInMembers(res.counts);
		};
		_countLoggedinMemberFunc();
	}, []);

	return (
		<div>
			{/* <!-- Index-Section1 --> */}
			<section className="index_sec1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="headingBg mb-4">
								<label className="subHeading">
									Come into our space to see to new technology and trends
								</label>
							</div>
							<p
								className="Heading pySpace heading_text"
								style={{
									fontSize: "45px",
								}}
							>
								Come join us to ex<span className="text_stroke">plore</span>
								<br /> our world in 3D-fu<span className="text_stroke">ll</span>
								<br />
								digital spectrum
							</p>
							<div className="row">
								<div className="col-lg-6">
									<div className="index_sec2_lyt_item">
										<p className="pera16">Explore Our 3D World".</p>
										{/* <p className="pera14">
									Diam sit amet nisl suscipit. A condimentum vitae sapien
									pellentesque habitant morbi tristique
								</p> */}
									</div>
								</div>
								<div className="col-lg-6"></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div
				id="register-popup-container"
				className="position-fixed d-none justify-content-center align-items-center"
				style={{
					left: "0",
					right: "0",
					top: "0",
					bottom: "0",
					opacity: "0",
					background: "rgba(0,0,0,0.9)",
					zIndex: "10",
					transition: "all 0.3s ease-in-out",
				}}
			>
				<RegisterPopup clearPopup={() => closeRegisterPopup()} />
			</div>

			{/* <!-- End-Index-Section1 -->
			<!-- Index-Section2 --> */}
			<section
				className="index_sec2"
			// style={{
			// 	marginTop: "150px",
			// }}
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md-6 col-lg-6">
							<div className="btnWrapper">
								{/* <Link to="/Spacein" className="customBtn">
									<img src="assets/images/btnBg.png" className="img-fluid" />
									<span>
										spaced in <i className="fa-solid fa-arrow-right"></i>
									</span> 
								</Link> */}
								<button
									type="button"
									className="customBtn"
									onClick={() => redirectToSpacedIn()}
								>
									<img src="assets/images/btnBg.png" className="img-fluid" />
									<span>
										spaced in <i className="fa-solid fa-arrow-right"></i>
									</span>
								</button>
								<Link to="/Kryp.ai" className="customBtn">
									<img src="assets/images/btnBg.png" className="img-fluid" />
									<span>
										Kryp.ai <i className="fa-solid fa-arrow-right"></i>
									</span>
								</Link>
							</div>
						</div>

						<div className="col-12 col-md-6 col-lg-6">
							<div className="index_sec2_ryt_item">
								<div className="row">
									<div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-4">
										<p className="pera12">NFTs Minted</p>
										<p className="pera50">{mints}</p>
									</div>
									<div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-4">
										<p className="pera12">MEMBERS REGISTERED</p>
										<p className="pera50">{members}</p>
									</div>
									<div className="col-12 col-sm-4 col-md-4 col-lg-4 mb-4">
										<p className="pera12">MEMBERS ONLINE</p>
										<p className="pera50">{loggedInMembers}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Home;
