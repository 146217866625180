import React from "react";

const CustomModal2 = ({ children, title, iframeSrc, id, iframe2 }) => {
	console.log(iframeSrc);
	return (
		<>
			<a
				className="bookmodal-anchor"
				data-bs-toggle="modal"
				data-bs-target={`#bookModal${id}`}
			>
				{title}
			</a>

			<div
				className="modal fade book_modal"
				id={`bookModal${id}`}
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							{iframeSrc?.map((items, index) => (
								<iframe
									key={index}
									title="Modal Content"
									src={items?.src}
									width="100%"
									height="1000px"
									frameBorder="0"
									style={{border: `none`}}
									overflow="scroll"
									allowFullScreen
								></iframe>
							))}
							{/* {iframe2 ? (
								<iframe
									title="Modal Content"
									src={iframe2}
									width="100%"
									height="400px"
									frameBorder="0"
									overflow="scroll"
									allowFullScreen
								></iframe>
							) : (
								children
							)}
						</div>
						<div className="modal-body">
							{iframeSrc ? (
								<iframe
									title="Modal Content"
									src={iframeSrc}
									width="100%"
									height="400px"
									frameBorder="0"
									overflow="scroll"
									allowFullScreen
								></iframe>
							) : (
								children
							)} */}
						</div>
						<div className="modal-footer d-none">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomModal2;
