import React, { useState, useRef, useEffect } from "react";
import { providers } from "ethers";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthApi } from "../services/AuthService";
import { BASE_URL } from "../services";
import Search from "./header-search/Search";
// import 'public/css/stellarnav.min.css';

function Header() {
	const menuRef = useRef(null);
	const navigate = useNavigate();
	const [user, setUser] = useState({});
	const [openSearch, setOpenSearch] = useState(false);
	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setMenuOpen(false);
		}
	};

	// const[menuOpen, setOpen] = useState(false);

	// useEffect(() => {
	// 	let handler = () => {
	// 		setOpen(false);
	// 	}

	// 	document.addEventListener("mousedown", handler);
	// });

	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const [walletAddress, setWalletAddress] = useState("");

	async function requestAccount() {
		console.log("Requesting account...");

		// Check if MetaMask extension exists //
		if (window.ethereum) {
			console.log("detect");
			try {
				const accounts = await window.ethereum.request({
					method: "eth_requestAccounts",
				});
				setWalletAddress(accounts[0]);
			} catch (error) {
				console.log("Error connecting...");
			}
		} else {
			console.log("Meta Mask not detected");
		}
	}

	async function connectWallet() {
		if (typeof window.ethereum !== "undefined") {
			await requestAccount();

			const provider = new ethers.providers.Web3Provider(window.ethereum);
		}
	}

	const logout = async () => {
		const res = await AuthApi.logout();
		setUser({});
		navigate("/login");
	};

	useEffect(() => {
		const _def = async () => {
			let user = await localStorage.getItem("user");
			if (user) {
				setUser(JSON.parse(user));
			}
		};
		_def();
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div>
			<header className="my-4">
				<div className="container">
					<div className="header_wrap">
						{/* <!-- Left-Sec-header --> */}
						<div className="header_col1">
							<div className="header_leftSec d-flex align-items-center">
								<div className="hamburger-menu" onClick={toggleMenu}>
									<div className="line"></div>
									<div className="line"></div>
									<div className="line"></div>
								</div>
								<div className={`menu ${menuOpen ? "show" : ""}`}>
									<div
										ref={menuRef}
										className={`menu ${menuOpen ? "show" : ""}`}
									>
										<ul onClick={toggleMenu}>
											<li >
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="./Kryp.ai">Kryp.ai</Link>
											</li>
											<li>
												<Link to="./Mission">Kryp.ai Cause</Link>
											</li>
											<li>
												<Link to="./Training">Kryp 101</Link>
											</li>
											<li>
												<Link to="./Connectwallet">Connect Wallet</Link>
											</li>
											<li>
												<Link to="./Contact">Get in Touch</Link>
											</li>
											<li>
												<Link to="./Faqs">FAQS</Link>
											</li>
											<li>
												<Link to="./videos">Videos</Link>
											</li>
										</ul>
									</div>
									<div className="cancel_btn">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											fill="#fff"
											class="bi bi-x"
											viewBox="0 0 16 16"
										>
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
										</svg>
									</div>
								</div>
								{/* <div className="stellarnav">

                                <ul>
                                    <li><Link to="./">Home</Link></li>
                                    <li><Link to="./cryppunks">Cryppunks</Link></li>
                                    <li><Link to="./mission">Cryppunks Cause</Link></li>
                                    <li><Link to="./training">Krypt 101</Link></li>
                                    <li><Link to="./connectwallet">Connect Wallet</Link></li>
                                    <li><Link to="./contact">Get in Touch</Link></li>
                                    <li><Link to="./faqs">FAQS</Link></li>
                                </ul>
                            </div> */}
								<div className="menuList_leftSec">
									<ul className="d-flex">
										<li className="">
											{/* <Link onClick={requestAccount} to="">Create your NFT</Link> */}
											<Link to="/login">Create your NFT</Link>
										</li>
										<li className="">
											<Link onClick={requestAccount} to="">
												Connect
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- End-Left-Sec-header -->

                    <!-- Mid-Sec-header --> */}
						<div className="header_col2">
							<div className="header_midSec">
								<Link to="/">
									<img
										src="/assets/images/logo.png"
										className="img-fluid"
										alt="Logo"
									/>
								</Link>
							</div>
						</div>
						{/* <!-- End-Mid-Sec-header -->

                    <!-- Mid-Sec-header --> */}
						<div className="header_col3">
							<div className="header_rytSec d-flex align-items-center justify-content-end">
								<div className="searchBox d-flex justify-content-evenly">
									<button
										className="btn-search"
										onClick={() => setOpenSearch(true)}
									>
										<i className="fas fa-search"></i>
									</button>

									<Search show={openSearch} setOpenSearch={setOpenSearch} />
								</div>
								<div className="header_right">
									{/* <!-- UL listing --> */}
									<ul className="brandList d-flex">
										<li>
											<Link to="https://www.linkedin.com/in/kryp-ai-992644276" target="blank">
												<img
													src="/assets/images/brand-icon1.png"
													alt="linkedin"
												/>
											</Link>
										</li>
										<li>
											<Link to="https://app.hive.com/workspace/bhpmGoLCsB9YsxeEc?actionViewId=list&userProfile=8QqGsYKBH9YbwhZAA" target="blank">
												<img
													src="/assets/images/brand-icon2.png"
													alt="brand-icon2"
												/>
											</Link>
										</li>
										<li>
											<Link to="https://www.instagram.com/kryp.ai/?igsh=YmdqZGJvdHh4NWty" target="blank">
												<img
													src="/assets/images/brand-icon3.png"
													alt="instagram"
												/>
											</Link>
										</li>
										<li>
											<Link to="https://www.facebook.com/profile.php?id=100092717004433&mibextid=ZbWKwL" target="blank">
												<img
													src="/assets/images/brand-icon4.png"
													alt="facebook"
												/>
											</Link>
										</li>
										<li>
											<Link to="#1" target="blank">
												<img
													src="/assets/images/brand-icon5.png"
													alt="twitter"
												/>
											</Link>
										</li>
										<li>
											<Link to="https://discord.com/invite/UD8TPT9W" target="blank">
												<img
													src="/assets/images/brand-icon6.png"
													alt="brand-icon6"
												/>
											</Link>
										</li>
									</ul>
									{/* <!-- Profile --> */}
									{Object.keys(user).length > 0 ? (
										<div id="user-title-wrapper" className="profileWrapper">
											<div
												className="d-flex align-items-center position-relative"
												type="button"
												id="dropdownMenuButton1"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												{Object.keys(user).length > 0 && user.picture ? (
													<img
														src={`${BASE_URL}/${user.picture}`}
														className="img-fluid"
													/>
												) : (
													<img
														src="/assets/images/profileImg.png"
														className="img-fluid"
													/>
												)}

												<label>
													<span>welcome back</span>
													<p className="text-white m-0">
														{Object.keys(user).length > 0 && user.firstName}
													</p>
												</label>
												<div id="dropdown" className="position-absolute p-3">
													<p className="text-white">
														{Object.keys(user).length > 0 && user.email}
													</p>
													<button
														className="btn w-100 bg-primary text-white"
														onClick={() => logout()}
													>
														Logout
													</button>
												</div>
											</div>
											<ul
												className="dropdown-menu"
												aria-labelledby="dropdownMenuButton1"
											>
												<li>
													<Link className="dropdown-item" href="#">
														<p>Logout</p>
													</Link>
												</li>
											</ul>
										</div>
									) : (
										<>
											<Link to={"/login"} className="text-white">
												Login
											</Link>
											<span className="text-white">&nbsp; / &nbsp;</span>
											<Link to={"/RegisterForm"} className="text-white">
												Register
											</Link>
										</>
									)}
								</div>
							</div>
						</div>
						{/* <!-- End-Mid-Sec-header --> */}
					</div>
				</div>
			</header>
		</div>
	);
}

export default Header;
