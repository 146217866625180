import React from "react";
import Home from "./Pages/Home";
import Bots from "./Pages/Bots";
import Connectwallet from "./Pages/Connectwallet";
import Contact from "./Pages/Contact";
import Kryp from "./Pages/Kryp.ai";
import Faqs from "./Pages/Faqs";
import Htmlcss from "./Pages/Htmlcss";
import Javacript from "./Pages/Javacript";
import Python from "./Pages/Python";
import Membershipform from "./Pages/Membershipform";
import RegisterForm from "./Pages/RegisterForm";
import Mission from "./Pages/Mission";
import Nftfeature from "./Pages/Nftfeature";
import Nftfeature2 from "./Pages/Nftfeature2";
import Nftmarketplace from "./Pages/Nftmarketplace";
import Privacy from "./Pages/Privacy";
import RoadMap from "./Pages/RoadMap";
import Spacedin from "./Pages/Spacedin";
import Spacedinregister from "./Pages/Spacedinregister";
import Spacein from "./Pages/Spacedin";
import Spaceinblockchain from "./Pages/Spaceinblockchain";
import Nftandblockchainsmartcontracts from "./Pages/Nftandblockchainsmartcontracts";
import Spaceinmission from "./Pages/Spaceinmission";
import Botsandtraining from "./Pages/Botsandtraining";
import Termconditions from "./Pages/Termconditions";
import Training from "./Pages/Training";
import UnderConstruction from "./Pages/UnderConstruction";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Login from "./Components/Login";
import AdminDashboard from "./Components/AdminDashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Members from "./Pages/Admin/Members";
import Subscription from "./Pages/subscription";
import Uiux from "./Pages/Uiux";
import Css from "./Pages/Css";
import Videos from "./Pages/Videos";

// ZIP
function App() {
	return (
		<div>
			<Router>
				<Header />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/login" element={<Login />} />

					<Route path="/AdminDashboard" element={<AdminDashboard />} />
					<Route path="/Members" element={<Members />} />

					<Route path="/Bots" element={<Bots />} />
					<Route path="/Connectwallet" element={<Connectwallet />} />
					<Route path="/Contact" element={<Contact />} />
					<Route path="/Kryp.ai" element={<Kryp />} />
					<Route path="/Botsandtraining" element={<Botsandtraining />} />
					<Route path="/Faqs" element={<Faqs />} />
					<Route path="/Htmlcss" element={<Htmlcss />} />
					<Route path="/Javacript" element={<Javacript />} />
					<Route path="/Python" element={<Python />} />
					<Route path="/Membershipform" element={<Membershipform />} />
					<Route path="/RegisterForm" element={<RegisterForm />} />
					<Route path="/Mission" element={<Mission />} />
					<Route path="/Nftfeature/:id" element={<Nftfeature />} />
					<Route path="/Nftfeature2" element={<Nftfeature2 />} />
					<Route path="/Nftmarketplace" element={<Nftmarketplace />} />
					<Route path="/Privacy" element={<Privacy />} />
					<Route path="/RoadMap" element={<RoadMap />} />
					<Route path="/Spacedin" element={<Spacedin />} />
					<Route path="/Spacedinregister" element={<Spacedinregister />} />
					<Route path="/membership" element={<Subscription />} />
					<Route path="/Spacein" element={<Spacein />} />
					<Route path="/Spaceinblockchain" element={<Spaceinblockchain />} />
					<Route path="/Spaceinmission" element={<Spaceinmission />} />
					<Route path="/Termconditions" element={<Termconditions />} />
					<Route path="/Training" element={<Training />} />
					<Route path="/UnderConstruction" element={<UnderConstruction />} />
					<Route
						path="/Nftandblockchainsmartcontracts"
						element={<Nftandblockchainsmartcontracts />}
					/>
          <Route path="/css" element={<Css />} />
					<Route path="/uiux" element={<Uiux />} />
					<Route path="/videos" element={<Videos/>} />
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
