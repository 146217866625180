import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import RegisterPopup from "../Components/RegisterPopup";

function RoadMap() {
	const OpenRegisterPopup = () => {
		let registerPopupContainer = document.querySelector(
			"#register-popup-container",
		);
		if (registerPopupContainer) {
			registerPopupContainer.classList.add("d-flex");
			registerPopupContainer.classList.remove("d-none");
			setTimeout(() => {
				registerPopupContainer.style.opacity = "1";
			}, 300);
		}
	};

	const closeRegisterPopup = () => {
		let registerPopupContainer = document.querySelector(
			"#register-popup-container",
		);
		if (registerPopupContainer) {
			registerPopupContainer.style.opacity = "0";
			setTimeout(() => {
				registerPopupContainer.classList.remove("d-flex");
				registerPopupContainer.classList.add("d-none");
			}, 300);
		}
	};

	useEffect(() => {
		// setTimeout(() => {
		//   OpenRegisterPopup();
		// }, 10000);
	}, []);

	return (
		<div>
			{/* <!-- BreadCrumb & Heading --> */}
			<section className="breadcrumb_flexRow">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							{/* <!--Breadcrumb--> */}
							<section className="breadCrumb_wrapper">
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<Link to="/">Home</Link>
										</li>
										<li className="breadcrumb-item">
											<Link to="/kryp.ai">Kryp.ai</Link>
										</li>
										<li className="breadcrumb-item active" aria-current="page">
											Road Map
										</li>
									</ol>
								</nav>
							</section>
							{/* <!--End Breadcrumb--> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- BreadCrumb & Heading  --> */}

			{/* <!-- Road Map-Section1 --> */}
			<section className="road_map_sec1">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div className="frame">
								<div
									className="frame_contentBox scrollBar"
									style={{
										marginTop: "60px",
										marginLeft: "100px",
										width: "88%",
										minHeight: "70%",
										maxHeight: "70%",
									}}
								>
									<div className="row">
										<div className="col-12 col-md-12 col-lg-12">
											<div className="headingBg text-center mb-3">
												<label className="subHeading font25">
													come into our space to see to new
												</label>
											</div>
											<p className="Heading font30 text-center m-auto">
												roadmap
											</p>
											{/* <!-- Row-2 --> */}
											<div className="road_map_sec2 road_map_img">
												<div className="row ">
													<div className="col-12 col-md-6 col-lg-6 ">
														<div className="road_map_wrapper road_map">
															<div className="map_scroll">
																<label>phase 1</label>
																{/* <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                              </p> */}
																<h5 className="font20">
																	<b>Phase 1 Awareness and Education</b>
																</h5>
																<ul className="p-0">
																	<li>
																		● <b>Goal: </b> Educate the public on
																		microplastics' dangers and spread.
																	</li>
																	<li>
																		● <b>Approach: </b>Utilize virtual reality
																		to visualize microplastics' impact.
																	</li>
																	<li>
																		<b>● Key Actions:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Develop VR experiences showing
																		microplastics' journey.
																	</li>
																	<li>
																		● Host expert webinars on microplastics
																		science and health risks.
																	</li>
																	<li>
																		● Partner with research institutions for
																		credible data.
																	</li>
																	<li>
																		● Generate educational content on
																		microplastics diversity and pervasiveness.
																	</li>
																</ul>
																<ul className="p-0">
																	<li>
																		<b>● Expected Outcomes:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Public awareness on microplastics
																		increases.
																	</li>
																</ul>
																<p>
																	Kryp.ai recognized for environmental
																	information within the metaverse.
																</p>
															</div>
															<div className="absSec">
																<img
																	src="assets/images/alien.png"
																	className="img-fluid"
																/>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-6 col-lg-6 ">
														<div className="road_map_wrapper road_map">
															{/* <label>phase 2</label> */}
															{/* <p>
																Lorem ipsum dolor sit amet, consectetur
																adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut
																enim ad minim veniam, quis nostrud exercitation
																ullamco laboris nisi ut aliquip ex ea commodo
																consequat. Duis aute irure dolor in
																reprehenderit in voluptate velit esse cillum
																dolore eu fugiat nulla pariatur. Excepteur sint
																occaecat cupidatat non proident, sunt in culpa
																qui officia deserunt mollit anim id est laborum.
															</p> */}
															<div className="map_scroll">
																<label>phase 2</label>
																<h5 className="font20">
																	<b>Phase 2: Engagement and Interaction</b>
																</h5>
																<ul className="p-0">
																	<li>
																		● <b>Goal: </b> Involve the community in
																		active measures against microplastics.
																	</li>
																	<li>
																		● <b>Approach: </b> Facilitate interactive
																		initiatives and encourage personal action.
																	</li>
																	<li>
																		<b>● Key Actions:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Implement interactive metaverse projects
																		where users can simulate the effects of
																		reducing plastic use.
																	</li>
																	<li>
																		● Encourage community challenges to reduce
																		personal plastic footprint.
																	</li>
																	<li>
																		● Launch a platform for users to share
																		experiences and solutions.
																	</li>
																</ul>
																<ul className="p-0">
																	<li>
																		<b>● Expected Outcomes:</b>
																	</li>
																	<li>
																		● A community actively engaged in reducing
																		microplastics.
																	</li>
																</ul>
																<ul>
																	<li>
																		● A collection of user-generated ideas and
																		strategies for tackling plastic pollution.
																	</li>
																</ul>
															</div>
															<div className="absSec">
																<img
																	src="assets/images/rocket.png"
																	className="img-fluid"
																/>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-6 col-lg-6 ">
														<div className="road_map_wrapper road_map">
															{/* <label>phase 3</label> */}
															{/* <p>
																Lorem ipsum dolor sit amet, consectetur
																adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut
																enim ad minim veniam, quis nostrud exercitation
																ullamco laboris nisi ut aliquip ex ea commodo
																consequat. Duis aute irure dolor in
																reprehenderit in voluptate velit esse cillum
																dolore eu fugiat nulla pariatur. Excepteur sint
																occaecat cupidatat non proident, sunt in culpa
																qui officia deserunt mollit anim id est laborum.
															</p> */}
															<div className="map_scroll">
																<label>phase 3</label>
																<h5 className="font20">
																	<b>Phase 3: Innovation and Development</b>
																</h5>
																<ul className="p-0">
																	<li>
																		● <b>Goal: </b> Create sustainable solutions
																		to reduce and manage microplastic pollution.
																	</li>
																	<li>
																		● <b>Approach: </b> Leverage technology and
																		community insights to develop practical
																		applications.
																	</li>
																	<li>
																		<b>● Key Actions:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Initiate metaverse-based innovation labs
																		for developing pollution-reduction
																		technologies.
																	</li>
																	<li>
																		● Support community-led projects that offer
																		real-world impact.
																	</li>
																	<li>
																		● Foster partnerships with eco-friendly
																		product developers.
																	</li>
																</ul>
																<ul className="p-0">
																	<li>
																		<b>● Expected Outcomes:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Technological advancements in
																		microplastics management.
																	</li>
																	<li>
																		● Increased availability of sustainable
																		alternatives to plastic products.
																	</li>
																</ul>
															</div>
															<div className="absSec">
																<img
																	src="assets/images/laser_gun.png"
																	className="img-fluid"
																/>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-6 col-lg-6 ">
														<div className="road_map_wrapper road_map">
															{/* <label>phase 4</label> */}
															{/* <p>
																Lorem ipsum dolor sit amet, consectetur
																adipisicing elit, sed do eiusmod tempor
																incididunt ut labore et dolore magna aliqua. Ut
																enim ad minim veniam, quis nostrud exercitation
																ullamco laboris nisi ut aliquip ex ea commodo
																consequat. Duis aute irure dolor in
																reprehenderit in voluptate velit esse cillum
																dolore eu fugiat nulla pariatur. Excepteur sint
																occaecat cupidatat non proident, sunt in culpa
																qui officia deserunt mollit anim id est laborum.
															</p> */}
															<div className="map_scroll">
																<label>phase 4</label>
																<h5 className="font20">
																	<b>
																		Phase 4: Global Impact and Policy Advocacy
																	</b>
																</h5>
																<ul className="p-0">
																	<li>
																		● <b>Goal: </b> Drive global change and
																		influence policies to address microplastic
																		pollution.
																	</li>
																	<li>
																		● <b>Approach: </b> Campaign for
																		international cooperation and legal
																		frameworks.
																	</li>
																	<li>
																		<b>● Key Actions:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Present data and solutions at
																		environmental forums.
																	</li>
																	<li>
																		● Engage with policymakers for
																		plastic-reduction regulations.
																	</li>
																</ul>
																<ul className="p-0">
																	<li>
																		<b>● Expected Outcomes:</b>
																	</li>
																</ul>
																<ul>
																	<li>
																		● Stronger global policies against
																		microplastics.
																	</li>
																	<li>
																		● Recognition of Kryp.ai as a key player in
																		environmental advocacy.
																	</li>
																</ul>
															</div>
															<div className="absSec">
																<img
																	src="assets/images/binoculars.png"
																	className="img-fluid"
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- End-Road Map-Section1  --> */}

			<div
				id="register-popup-container"
				className="position-fixed d-none justify-content-center align-items-center"
				style={{
					left: "0",
					right: "0",
					top: "0",
					bottom: "0",
					opacity: "0",
					background: "rgba(0,0,0,0.8)",
					zIndex: "10",
					transition: "all 0.3s ease-in-out",
				}}
			>
				<RegisterPopup clearPopup={() => closeRegisterPopup()} />
			</div>
		</div>
	);
}

export default RoadMap;
