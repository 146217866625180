import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../services/AuthService";

const RegisterFormSection = () => {
  const [firstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const onRegister = async (e) => {
    e.preventDefault();

    const userDetails = new FormData();
    userDetails.append("firstName", firstName);
    userDetails.append("lastName", LastName);
    userDetails.append("email", email);
    userDetails.append("password", password);
    userDetails.append("confirmPassword", confirmPassword);
    if (avatar) {
      userDetails.append("avatar", avatar);
    }

    if (password != confirmPassword) {
      setError("Passsword must be match");
    } else {
      const res = await AuthApi.singUp(userDetails);
      console.log("res", res);
      if (res.status === 400) {
        setError(res.message);
      } else if (res.success) {
        clearForm("");
        navigate("/login");
      }
    }
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <form onSubmit={(e) => onRegister(e)}>
      <div className="form_wrapper px-5">
        <div className="row">
          <div className="col-12">
            {error && <div className="text-danger">{error}</div>}
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="fieldWrap mb-3">
              <label for="fName">First Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                id="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="fieldWrap mb-3">
              <label for="lastName">Last Name</label>
              <input
                type="text"
                placeholder="Enter your last name"
                id="lastName"
                value={LastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-12">
            <div className="fieldWrap mb-3">
              <label for="email">Email</label>
              <input
                type="email"
                placeholder="Enter your Email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="fieldWrap mb-3">
              <label for="email">Password</label>
              <input
                type="password"
                placeholder="Password"
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="fieldWrap mb-3">
              <label for="email">Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="fieldWrap mb-3">
              <label for="file">Avatar</label>
              <input
                type="file"
                id="file"
                onChange={(e) => {
                  setAvatar(e.target.files[0]);
                }}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="btnWrapper mt-2">
          <button type="submit" className="customBtn">
            <img src="assets/images/btnBg.png" className="img-fluid" />
            <span>
              Submit <i className="fa-solid fa-arrow-right"></i>
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default RegisterFormSection;
